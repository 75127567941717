import styled from "@/theme";
import { Input } from "antd";

interface SearchComponentProps {
  value?: string;
  onSearch: (value: string) => void;
}

const Search: React.FC<SearchComponentProps> = ({ value, onSearch }) => {
  const handleSearch = (value: string) => {
    onSearch(value);
  };

  return (
    <Container>
      <StyledInput
        placeholder="고객명 검색"
        allowClear
        value={value}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  width: 212px;
  height: 42px;
`;

export default Search;
