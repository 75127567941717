import styled from "@/theme";
import { Checkbox } from "antd";
import { STATUS_TYPES, StatusType } from ".";
import { CheckboxState } from ".";

type CheckboxTableProps<T extends string> = {
  title: string;
  products: readonly T[];
  checkboxState: CheckboxState<T>;
  onCheckboxChange: (status: StatusType, product: T) => void;
};

const CheckboxTable = <T extends string>({
  title,
  products,
  checkboxState,
  onCheckboxChange,
}: CheckboxTableProps<T>) => (
  <TableContainer>
    <TableDesc>{title}</TableDesc>
    <Table>
      <thead>
        <Tr>
          <Th></Th>
          {products.map((product) => (
            <Th key={product}>{product}</Th>
          ))}
        </Tr>
      </thead>
      <tbody>
        {STATUS_TYPES.map((status) => (
          <Tr key={status}>
            <Td backgroundColor="gray50">{status}</Td>
            {products.map((product) => (
              <Td key={`${status}-${product}`}>
                <Checkbox
                  checked={checkboxState[status][product]}
                  onChange={() => onCheckboxChange(status, product)}
                />
              </Td>
            ))}
          </Tr>
        ))}
      </tbody>
    </Table>
  </TableContainer>
);

const TableContainer = styled.div`
  margin-top: 32px;
`;

const TableDesc = styled.div`
  color: var(--Gray-Gray-400, #7a8190);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */

  margin-bottom: 6px;
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-radius: 12px;
  background: var(--Gray-Gray-50, #f7f8fa);
  border: 1px solid #bdc2ce;
`;

const Th = styled.th`
  padding: 16px;
  text-align: center;
  color: var(--Color-Dark-Dark, #23262f);

  ${(p) => p.theme.text.p3Semibold};
`;

const Tr = styled.tr`
  & > td,
  & > th {
    border-bottom: 1px solid ${(p) => p.theme.colors.gray200};
  }

  &:last-child > td {
    border-bottom: none;
  }
`;

const Td = styled.td<{ backgroundColor?: string }>`
  padding: 16px;
  text-align: center;

  background-color: ${({ backgroundColor }) => backgroundColor ?? "white"};

  width: ${({ backgroundColor }) =>
    backgroundColor === "gray50" ? "60px" : "auto"};

  ${(p) => p.theme.text.p3Semibold};
`;

export default CheckboxTable;
