import styled from "@/theme";

type SpaceProps = {
  size?: number;
  width?: number;
  w?: number;
  x?: number;
  height?: number;
  h?: number;
  y?: number;
};
const Space = styled.div<SpaceProps>`
  width: ${(p) => p.width ?? p.w ?? p.x ?? p.size ?? 0}px;
  height: ${(p) => p.height ?? p.h ?? p.y ?? p.size ?? 0}px;
  flex-shrink: 0;
`;

export default Space;
