import { gray, primary, shades, sub01, sub02, sub03, sub04 } from './baseColors';

const tabsColors = {
  tabsBorder: gray.gray100,
  tabButtonNormalText: gray.gray400,
  tabButtonSelectedText: shades.black,

  tabMainButtonInactiveSurface: gray.gray50,
  tabMainButtonInactiveText: gray.gray400,
  tabMainButtonDisabledSurface: gray.gray50,
  tabMainButtonDisabledText: gray.gray200,
  tabMainButtonPrimarySurface: primary.primary300,
  tabMainButtonPrimaryText: shades.white,
  tabMainButtonPrimary02Surface: primary.primary400,
  tabMainButtonPrimary02Text: shades.white,
  tabMainButtonSub01Surface: sub01.sub01_400,
  tabMainButtonSub01Text: shades.white,
  tabMainButtonSub02Surface: sub02.sub02_400,
  tabMainButtonSub02Text: shades.white,
  tabMainButtonSub03Surface: sub03.sub03_400,
  tabMainButtonSub03Text: shades.white,
  tabMainButtonSub04Surface: sub04.sub04_400,
  tabMainButtonSub04Text: shades.white,
  tabMainOnGrayButtonInactiveSurface: shades.white,

  tabFilterButtonInactiveSurface: shades.white,
  tabFilterButtonInactiveText: gray.gray400,
  tabFilterButtonDisabledSurface: shades.white,
  tabFilterButtonDisabledText: gray.gray400,
  tabFilterButtonPrimarySurface: primary.primary100,
  tabFilterButtonPrimaryText: primary.primary400,

  // tabFilterButtonPrimary02Surface: primary.primary400,
  // tabFilterButtonPrimary02Text: shades.white,
  tabFilterButtonSub01Surface: sub01.sub01_200,
  tabFilterButtonSub01Text: sub01.sub01_400,
  tabFilterButtonSub02Surface: sub02.sub02_200,
  tabFilterButtonSub02Text: sub02.sub02_400,
  tabFilterButtonSub03Surface: sub03.sub03_200,
  tabFilterButtonSub03Text: sub03.sub03_400,
  tabFilterButtonSub04Surface: sub04.sub04_200,
  tabFilterButtonSub04Text: sub04.sub04_400,
  tabFilterOnGrayButtonInactiveSurface: shades.white,

  tabScopeButtonInactiveText: gray.gray600,
  tabScopeButtonPrimaryText: primary.primary400,
  tabScopeButtonDisabledSurface: 'transparent',
  tabScopeButtonDisabledText: gray.gray300,
  tabScopeButtonSub01Text: sub01.sub01_400,
  tabScopeButtonSub02Text: sub02.sub02_400,
  tabScopeButtonSub03Text: sub03.sub03_400,
  tabScopeButtonSub04Text: sub04.sub04_400,

  tabPeriodButtonInactiveSurface: 'transparent',
  tabPeriodButtonInactiveText: gray.gray400,
  tabPeriodButtonDisabledSurface: 'transparent',
  tabPeriodButtonDisabledText: gray.gray100,
  tabPeriodButtonPrimarySurface: gray.gray100,
  tabPeriodButtonPrimaryText: gray.gray600,
  tabPeriodButtonPrimary02Surface: gray.gray100,
  tabPeriodButtonPrimary02Text: gray.gray600,
  tabPeriodButtonSub01Surface: gray.gray100,
  tabPeriodButtonSub01Text: gray.gray600,
  tabPeriodButtonSub02Surface: gray.gray100,
  tabPeriodButtonSub02Text: gray.gray600,
  tabPeriodButtonSub03Surface: gray.gray100,
  tabPeriodButtonSub03Text: gray.gray600,
  tabPeriodButtonSub04Surface: gray.gray100,
  tabPeriodButtonSub04Text: gray.gray600,

  tabPageButtonPrimaryText: shades.black,
  tabPageButtonInactiveSurface: 'transparent',
  tabPageButtonInactiveText: gray.gray400,
  tabPageButtonDisabledSurface: 'transparent',
  tabPageButtonDisabledText: gray.gray400,
};

export default tabsColors;
