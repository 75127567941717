/**
 * Do not edit manually.
 * Frism Admin Server
 * OpenAPI spec version: admin
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AdminUsersDtoApiResponse,
  AllTokenDtoApiResponse,
  ApiResponse,
  ChangePasswordModel,
  CognitoMfaTokenModel,
  CognitoRefreshTokenModel,
  CognitoSignInModel,
  CognitoSignUpModel,
  ConfirmForgotPasswordResponseApiResponse,
  ForgotPasswordDto,
  ForgotPasswordResponseApiResponse,
  MfaSessionDtoApiResponse,
  SignUpResponseApiResponse,
  TokenDtoApiResponse,
} from "../../model";
import { customAxios } from "../../mutator/customAxios";
import type { ErrorType } from "../../mutator/customAxios";

/**
 * @summary 어드민 서버 가입 요청 / 승인 및 역할 할당은 관리자가 별도로 수행해야 함
 */
export const postAdminUserSignUp = (cognitoSignUpModel: CognitoSignUpModel) => {
  return customAxios<SignUpResponseApiResponse>({
    url: `/admin/user/sign-up`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: cognitoSignUpModel,
  });
};

export const getPostAdminUserSignUpMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserSignUp>>,
    TError,
    { data: CognitoSignUpModel },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminUserSignUp>>,
  TError,
  { data: CognitoSignUpModel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminUserSignUp>>,
    { data: CognitoSignUpModel }
  > = (props) => {
    const { data } = props ?? {};

    return postAdminUserSignUp(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminUserSignUpMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminUserSignUp>>
>;
export type PostAdminUserSignUpMutationBody = CognitoSignUpModel;
export type PostAdminUserSignUpMutationError = ErrorType<unknown>;

/**
 * @summary 어드민 서버 가입 요청 / 승인 및 역할 할당은 관리자가 별도로 수행해야 함
 */
export const usePostAdminUserSignUp = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserSignUp>>,
    TError,
    { data: CognitoSignUpModel },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminUserSignUp>>,
  TError,
  { data: CognitoSignUpModel },
  TContext
> => {
  const mutationOptions = getPostAdminUserSignUpMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 어드민 서버 AccessToken 재발급
 */
export const postAdminUserRefresh = (
  cognitoRefreshTokenModel: CognitoRefreshTokenModel,
) => {
  return customAxios<TokenDtoApiResponse>({
    url: `/admin/user/refresh`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: cognitoRefreshTokenModel,
  });
};

export const getPostAdminUserRefreshMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserRefresh>>,
    TError,
    { data: CognitoRefreshTokenModel },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminUserRefresh>>,
  TError,
  { data: CognitoRefreshTokenModel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminUserRefresh>>,
    { data: CognitoRefreshTokenModel }
  > = (props) => {
    const { data } = props ?? {};

    return postAdminUserRefresh(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminUserRefreshMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminUserRefresh>>
>;
export type PostAdminUserRefreshMutationBody = CognitoRefreshTokenModel;
export type PostAdminUserRefreshMutationError = ErrorType<unknown>;

/**
 * @summary 어드민 서버 AccessToken 재발급
 */
export const usePostAdminUserRefresh = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserRefresh>>,
    TError,
    { data: CognitoRefreshTokenModel },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminUserRefresh>>,
  TError,
  { data: CognitoRefreshTokenModel },
  TContext
> => {
  const mutationOptions = getPostAdminUserRefreshMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 어드민 서버 MFA 토큰 인증
 */
export const postAdminUserMfa = (
  cognitoMfaTokenModel: CognitoMfaTokenModel,
) => {
  return customAxios<AllTokenDtoApiResponse>({
    url: `/admin/user/mfa`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: cognitoMfaTokenModel,
  });
};

export const getPostAdminUserMfaMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserMfa>>,
    TError,
    { data: CognitoMfaTokenModel },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminUserMfa>>,
  TError,
  { data: CognitoMfaTokenModel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminUserMfa>>,
    { data: CognitoMfaTokenModel }
  > = (props) => {
    const { data } = props ?? {};

    return postAdminUserMfa(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminUserMfaMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminUserMfa>>
>;
export type PostAdminUserMfaMutationBody = CognitoMfaTokenModel;
export type PostAdminUserMfaMutationError = ErrorType<unknown>;

/**
 * @summary 어드민 서버 MFA 토큰 인증
 */
export const usePostAdminUserMfa = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserMfa>>,
    TError,
    { data: CognitoMfaTokenModel },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminUserMfa>>,
  TError,
  { data: CognitoMfaTokenModel },
  TContext
> => {
  const mutationOptions = getPostAdminUserMfaMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 어드민 서버 로그인(이후 MFA 필요)
 */
export const postAdminUserSignIn = (cognitoSignInModel: CognitoSignInModel) => {
  return customAxios<MfaSessionDtoApiResponse>({
    url: `/admin/user/sign-in`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: cognitoSignInModel,
  });
};

export const getPostAdminUserSignInMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserSignIn>>,
    TError,
    { data: CognitoSignInModel },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminUserSignIn>>,
  TError,
  { data: CognitoSignInModel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminUserSignIn>>,
    { data: CognitoSignInModel }
  > = (props) => {
    const { data } = props ?? {};

    return postAdminUserSignIn(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminUserSignInMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminUserSignIn>>
>;
export type PostAdminUserSignInMutationBody = CognitoSignInModel;
export type PostAdminUserSignInMutationError = ErrorType<unknown>;

/**
 * @summary 어드민 서버 로그인(이후 MFA 필요)
 */
export const usePostAdminUserSignIn = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserSignIn>>,
    TError,
    { data: CognitoSignInModel },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminUserSignIn>>,
  TError,
  { data: CognitoSignInModel },
  TContext
> => {
  const mutationOptions = getPostAdminUserSignInMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 어드민 서버 로그아웃
 */
export const postAdminUserSignOut = () => {
  return customAxios<ApiResponse>({
    url: `/admin/user/sign-out`,
    method: "POST",
  });
};

export const getPostAdminUserSignOutMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserSignOut>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminUserSignOut>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminUserSignOut>>,
    void
  > = () => {
    return postAdminUserSignOut();
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminUserSignOutMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminUserSignOut>>
>;

export type PostAdminUserSignOutMutationError = ErrorType<unknown>;

/**
 * @summary 어드민 서버 로그아웃
 */
export const usePostAdminUserSignOut = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserSignOut>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminUserSignOut>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getPostAdminUserSignOutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 비밀번호 재설정 용 임시코드 발급
 */
export const postAdminUserPasswordCode = (
  forgotPasswordDto: ForgotPasswordDto,
) => {
  return customAxios<ForgotPasswordResponseApiResponse>({
    url: `/admin/user/password/code`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: forgotPasswordDto,
  });
};

export const getPostAdminUserPasswordCodeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserPasswordCode>>,
    TError,
    { data: ForgotPasswordDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminUserPasswordCode>>,
  TError,
  { data: ForgotPasswordDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminUserPasswordCode>>,
    { data: ForgotPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return postAdminUserPasswordCode(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminUserPasswordCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminUserPasswordCode>>
>;
export type PostAdminUserPasswordCodeMutationBody = ForgotPasswordDto;
export type PostAdminUserPasswordCodeMutationError = ErrorType<unknown>;

/**
 * @summary 비밀번호 재설정 용 임시코드 발급
 */
export const usePostAdminUserPasswordCode = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminUserPasswordCode>>,
    TError,
    { data: ForgotPasswordDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminUserPasswordCode>>,
  TError,
  { data: ForgotPasswordDto },
  TContext
> => {
  const mutationOptions = getPostAdminUserPasswordCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 비밀번호 변경
 */
export const patchAdminUserPassword = (
  changePasswordModel: ChangePasswordModel,
) => {
  return customAxios<ConfirmForgotPasswordResponseApiResponse>({
    url: `/admin/user/password`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: changePasswordModel,
  });
};

export const getPatchAdminUserPasswordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAdminUserPassword>>,
    TError,
    { data: ChangePasswordModel },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchAdminUserPassword>>,
  TError,
  { data: ChangePasswordModel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchAdminUserPassword>>,
    { data: ChangePasswordModel }
  > = (props) => {
    const { data } = props ?? {};

    return patchAdminUserPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchAdminUserPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchAdminUserPassword>>
>;
export type PatchAdminUserPasswordMutationBody = ChangePasswordModel;
export type PatchAdminUserPasswordMutationError = ErrorType<unknown>;

/**
 * @summary 비밀번호 변경
 */
export const usePatchAdminUserPassword = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAdminUserPassword>>,
    TError,
    { data: ChangePasswordModel },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchAdminUserPassword>>,
  TError,
  { data: ChangePasswordModel },
  TContext
> => {
  const mutationOptions = getPatchAdminUserPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 어드민 유저 조회
 */
export const getAdminUserUsers = (signal?: AbortSignal) => {
  return customAxios<AdminUsersDtoApiResponse>({
    url: `/admin/user/users`,
    method: "GET",
    signal,
  });
};

export const getGetAdminUserUsersQueryKey = () => {
  return [`/admin/user/users`] as const;
};

export const getGetAdminUserUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminUserUsers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminUserUsers>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAdminUserUsersQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminUserUsers>>
  > = ({ signal }) => getAdminUserUsers(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminUserUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminUserUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminUserUsers>>
>;
export type GetAdminUserUsersQueryError = ErrorType<unknown>;

/**
 * @summary 어드민 유저 조회
 */
export const useGetAdminUserUsers = <
  TData = Awaited<ReturnType<typeof getAdminUserUsers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminUserUsers>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAdminUserUsersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
