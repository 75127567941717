import styled from "@/theme";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import { useState } from "react";

const { Search } = Input;

interface SearchComponentProps {
  onSearch: (value: string) => void;
  onRefresh: () => void;
}

const SearchComponent: React.FC<SearchComponentProps> = ({
  onSearch,
  onRefresh: _onRefresh,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearch = (value: string) => {
    if (!value || value === "") {
      _onRefresh();
      return;
    }
    onSearch(value);
  };

  const onRefresh = () => {
    setSearchTerm("");
    _onRefresh();
  };

  return (
    <Container>
      <Space>
        <Search
          placeholder="고객 이름을 입력하세요"
          enterButton="조회하기"
          size="large"
          onSearch={handleSearch}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          type="default"
          size="large"
          icon={<ReloadOutlined />}
          onClick={onRefresh}
        />
      </Space>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
`;

export default SearchComponent;
