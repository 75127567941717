import { InvestmentSolicitorUserResDto } from "@/api";
import tokens from "@/tokens";
import formatBirthDate from "@/utils/formatBirthDate";
import { Button } from "antd";
import { TableProps } from "antd/es/table";

type UseCustomersColumnsOptions = {
  data?: InvestmentSolicitorUserResDto[];
  onClickDetail: (userId: string) => void;
};

const useCustomersColumns = ({
  data,
  onClickDetail,
}: UseCustomersColumnsOptions) => {
  const dataSource = data?.map((obj, index) => ({
    ...obj,
    key: index,
    userName: obj.user.userName,
    phoneNumber: obj.user.phoneNumber,
    birthDate: obj.user.birthDate,
    actions: {
      userId: obj.user.userId,
    },
  }));

  console.log("test2", tokens.isInvestmentSolicitor);

  const columns: TableProps["columns"] = [
    {
      title: "이름",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "전화번호",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "생년월일",
      dataIndex: "birthDate",
      key: "birthDate",
      render: (date) => formatBirthDate(date),
    },
    {
      dataIndex: "actions",
      key: "actions",
      title: "계좌 목록",
      render: (row) => (
        <Button type="link" onClick={() => onClickDetail(row.userId)}>
          상세
        </Button>
      ),
    },
  ];

  if (!tokens.isInvestmentSolicitor) {
    columns.unshift({
      title: "고객 ID",
      dataIndex: "userId",
      key: "userId",
    });
  }

  return { columns, dataSource };
};

export default useCustomersColumns;
