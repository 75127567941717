import { ReactNode } from "react";
import {
  QueryClientProvider as Provider,
  QueryClient,
} from "@tanstack/react-query";

// react-query QueryClientProvider 에 주입할 queryClient 생성
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // API 요청 실패시 재요청
      retry: false,
    },
  },
});

type QueryClientProviderProps = {
  children: ReactNode;
};

const QueryClientProvider = ({ children }: QueryClientProviderProps) => {
  return <Provider client={queryClient}>{children}</Provider>;
};

export default QueryClientProvider;
