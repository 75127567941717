import {
  ConsultingOptionsParams,
  ReservationConfirmedResDto,
  ReservationWaitingResDto,
} from "@/api";
import formatReservationDate from "@/utils/formatReservationDate";
import { Button } from "antd";
import { TableProps } from "antd/es/table";
import { useState } from "react";
import ConsultingApprovalModal from "@/components/ConsultingApprovalModal";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber";
import Tag from "@/components/Tag";
import styled from "@/theme";
import StatusModal from "./StatusModal";
import { AnyObject } from "antd/es/_util/type";

type UseReservationColumnsOptions = {
  type: "waiting" | "confirmed";
  data?: ReservationConfirmedResDto[] | ReservationWaitingResDto[];
  isInvestmentSolicitorAdmin: boolean;
  onClickDetail: (reservationId: number) => void;
  onApproveConsulting: (id: number, params: ConsultingOptionsParams) => void;
};

const useReservationColumns = ({
  data,
  type,
  isInvestmentSolicitorAdmin,
  onClickDetail,
  onApproveConsulting,
}: UseReservationColumnsOptions) => {
  const [showApprovalModal, setShowApprovalModal] = useState<boolean>(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [selectedReservationId, setSelectedReservationId] = useState<
    number | null
  >(null);

  const dataSource = data?.map((obj, index) => ({
    ...obj,
    key: index,
    actions: {
      consultingReservationId: obj.consultingReservationId,
      consultingTypeCode: obj.consultingTypeCode,
      isConsultationAvailable:
        "isConsultationAvailable" in obj ? obj.isConsultationAvailable : false,
    },
  }));

  const handleActionClick = (
    reservationId: number,
    action: "approval" | "status" | "change"
  ) => {
    setSelectedReservationId(reservationId);
    if (action === "approval") {
      setShowApprovalModal(true);
    } else if (action === "change") {
    } else {
      setShowStatusModal(true);
    }
  };

  const renderActions = (record: AnyObject) => (
    <ButtonWrapper>
      <Button
        onClick={() => onClickDetail(record.actions.consultingReservationId)}
      >
        고객 정보
      </Button>
      <Button
        type="primary"
        disabled={record.actions.isConsultationAvailable}
        onClick={() =>
          handleActionClick(
            record.actions.consultingReservationId,
            type === "waiting"
              ? "approval"
              : isInvestmentSolicitorAdmin
                ? "change"
                : "status"
          )
        }
      >
        {type === "waiting"
          ? "상담 승인"
          : isInvestmentSolicitorAdmin
            ? "예약 변경"
            : "상태 설정"}
      </Button>
    </ButtonWrapper>
  );

  const columns: TableProps["columns"] = [
    {
      title: "고객명",
      dataIndex: "crmCustomerName",
      key: "crmCustomerName",
    },
    {
      title: "상담유형",
      dataIndex: "consultingTypeCode",
      key: "consultingTypeCode",
      render: (consultingTypeCode: string) => {
        return (
          <TagWrapper>
            <Tag
              color={
                consultingTypeCode === "대면상담"
                  ? "positive"
                  : consultingTypeCode === "유선상담"
                    ? "sub03"
                    : "sub05"
              }
              text={consultingTypeCode}
            />
          </TagWrapper>
        );
      },
    },
    ...(type === "waiting"
      ? [
          {
            title: "접수 경과일수",
            dataIndex: "reservationRequestedDtm",
            key: "reservationRequestedDtm",
            render: (date: string) => {
              const diffDays = Math.floor(
                (new Date().getTime() - new Date(date).getTime()) /
                  (1000 * 60 * 60 * 24)
              );
              return `D+${diffDays}`;
            },
          },
        ]
      : []),
    {
      title: "예약일자",
      dataIndex: "consultingStartDtm",
      key: "consultingStartDtm",
      render: (date: string) => {
        console.log(date);
        if (type === "waiting") {
          return formatReservationDate(date);
        }
        return formatReservationDate(date, { dateOnly: true });
      },
    },
    ...(type === "confirmed"
      ? [
          {
            title: "예약시간",
            dataIndex: "consultingStartDtm",
            key: "consultingStartDtm",
            render: (date: string) =>
              formatReservationDate(date, { timeOnly: true }),
          },
          {
            title: "고객 연락처",
            dataIndex: "crmCustomerPhoneNum",
            key: "crmCustomerPhoneNum",
            render: (phoneNum: string) => formatPhoneNumber(phoneNum),
          },
        ]
      : []),

    {
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => renderActions(record),
    },
  ];

  return {
    columns,
    dataSource,
    ApprovalModal: selectedReservationId && (
      <ConsultingApprovalModal
        isOpen={showApprovalModal}
        onClose={() => setShowApprovalModal(false)}
        onConfirm={(params) => {
          onApproveConsulting(selectedReservationId, params);
        }}
      />
    ),
    StatusModal: selectedReservationId && (
      <StatusModal
        showModal={showStatusModal}
        onClose={() => setShowStatusModal(false)}
        onConfirm={() => {}}
      />
    ),
  };
};

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export default useReservationColumns;
