import { Buffer } from "buffer";

type ParsedJwt = {
  exp: number;
  iat: number;
  username: string;
  "cognito:groups": string[];
};
function parseJwt(token: string) {
  const base64Payload = token.split(".")[1] ?? "";
  const jsonPayload = decodeURIComponent(
    Buffer.from(base64Payload, "base64").toString("utf8")
  );
  return JSON.parse(jsonPayload) as ParsedJwt;
}

export default parseJwt;
