import { usePostAdminUserSignIn } from "@/api";
import ErrorMessage from "@/components/ErrorMessage";
import LoadingOverlay from "@/components/LoadingOverlay";
import styled from "@/theme";
import getErrorMessageFromRes from "@/utils/getErrorMessageFromRes";
import { LockOutlined } from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";

type LoginFormProps = {
  onSuccess?: (email: string, session: string) => void;
};
type LoginFormState = {
  email: string;
  password: string;
  remember: boolean;
};
const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { mutateAsync: signIn, isPending, error } = usePostAdminUserSignIn();

  const navigate = useNavigate();

  const onClickRegister = () => {
    navigate("/register");
  };

  const onClickPasswordReset = () => {
    navigate("/password-reset");
  };

  const onFinish = async (data: LoginFormState) => {
    const res = await signIn({ data: data });

    if (res.result?.session) {
      if (data.remember) {
        localStorage.setItem("username", data.email);
        localStorage.setItem("rememberMe", "true");
      } else {
        localStorage.removeItem("username");
        localStorage.removeItem("rememberMe");
      }

      onSuccess?.(data.email, res.result?.session);
    }
  };

  const errorMessage = getErrorMessageFromRes(error);

  const getInitialValue = () => {
    const rememberMeValue = localStorage.getItem("rememberMe") === "true";
    if (rememberMeValue) {
      const savedUsername = localStorage.getItem("username");
      return {
        email: savedUsername,
        remember: true,
      };
    }

    return {
      remember: false,
    };
  };

  const initialValue = getInitialValue();

  return (
    <Container>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={initialValue}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: "이메일을 입력해 주세요." }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="이메일"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "비밀번호를 입력해 주세요." }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="비밀번호"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>정보 기억하기</Checkbox>
          </Form.Item>

          <ForgetPassword onClick={onClickPasswordReset}>
            비밀번호 재설정
          </ForgetPassword>
        </Form.Item>

        <Form.Item>
          <LoginButton
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            로그인
          </LoginButton>
          <Register onClick={onClickRegister}>회원가입</Register>
        </Form.Item>
      </Form>

      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {isPending && <LoadingOverlay />}
    </Container>
  );
};

const Container = styled.div`
  width: 300px;
`;

const ForgetPassword = styled.a`
  float: right;
`;

const LoginButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`;

const Register = styled.a``;

export default LoginForm;
