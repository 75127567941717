import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import envs from "@/envs";
import { ApiResponse } from "../model";

export const axiosInstance = axios.create({
  baseURL: envs.apiHost,

  /* NOTE: kunhee.lim
   *  회원 탈퇴시 시간이 오래걸리는 케이스가 존재할 수 있어서 일주님과 상의 후 1분으로 수정 */
  timeout: 60 * 1000,
});

export const customAxios = async <T>(
  config: AxiosRequestConfig
): Promise<T> => {
  const method = config.method ?? "GET";
  const url = config.url;

  console.log(method, url);

  if (!config.headers) {
    config.headers = {};
  }

  if (config.baseURL !== envs.apiHost) {
    config.baseURL = envs.apiHost;
  }

  try {
    const response: AxiosResponse<T> = await axiosInstance(config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export class FrismError<T> extends AxiosError<T> {}

export interface ErrorType<Error> extends FrismError<Error> {}

export type CustomErrorType = FrismError<ApiResponse>;
