const formatReservationDate = (
  dateString: string | undefined,
  options: {
    timeOnly?: boolean;
    dateOnly?: boolean;
  } = {}
) => {
  if (!dateString) return "";
  const date = new Date(dateString);

  // 시간 관련
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "오후" : "오전";
  const formattedHours = hours % 12 || 12;
  const timeStr = `${ampm} ${formattedHours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

  // 날짜 관련
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dayOfWeek = ["일", "월", "화", "수", "목", "금", "토"][date.getDay()];
  const dateStr = `${year}년 ${month}월 ${day}일(${dayOfWeek})`;

  if (options.timeOnly) return timeStr;
  if (options.dateOnly) return dateStr;
  return `${dateStr} ${timeStr}`;
};

export default formatReservationDate;
