import { Button, Form, Input, notification } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useImmer } from "use-immer";
import {
  ChangePasswordModel,
  usePatchAdminUserPassword,
  usePostAdminUserPasswordCode,
} from "@/api";
import getErrorMessageFromRes from "@/utils/getErrorMessageFromRes";
import styled from "@/theme";
import ErrorMessage from "@/components/ErrorMessage";
import LoadingOverlay from "@/components/LoadingOverlay";

type PasswordResetFormProps = {
  onSuccess?: () => void;
};
type PasswordResetFormState = ChangePasswordModel;
function PasswordResetForm({ onSuccess }: PasswordResetFormProps) {
  const [state, setState] = useImmer({
    email: "",
  });
  const {
    mutateAsync: patchPassword,
    isPending,
    error,
  } = usePatchAdminUserPassword({
    mutation: {
      onError: () => {
        notification.error({
          placement: "bottomRight",
          duration: 3,
          message: "에러",
          description: "인증코드를 다시 입력해 주세요.",
        });
      },
    },
  });
  const { mutateAsync: postCode, isPending: codeIsPending } =
    usePostAdminUserPasswordCode({
      mutation: {
        onSuccess: () => {
          notification.success({
            placement: "bottomRight",
            duration: 3,
            message: "성공",
            description: "임시코드가 발송되었습니다.",
          });
        },
      },
    });

  const onFinish = async (data: PasswordResetFormState) => {
    const { ...args } = data;
    const res = await patchPassword({ data: args });
    if (res.result) {
      onSuccess?.();
    }
  };

  const onGetCode = async () => {
    const emailRegex: RegExp =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(state.email)) {
      await postCode({
        data: {
          email: state.email,
        },
      });
    } else {
      notification.warning({
        placement: "bottomRight",
        duration: 3,
        message: "에러",
        description: "이메일을 확인해 주세요.",
      });
    }
  };

  const errorMessage = getErrorMessageFromRes(error);

  const onValuesChange = ({ email }: { email: string }) => {
    setState((dr) => {
      dr.email = email;
    });
  };
  return (
    <Container>
      <Form
        name="normal_login"
        className="login-form"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: "이메일을 입력해 주세요." }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="이메일"
          />
        </Form.Item>
        <Form.Item
          name="confirmationCode"
          rules={[{ required: true, message: "임시코드를 입력해 주세요." }]}
        >
          <Input.Search
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="임시코드"
            loading={codeIsPending}
            enterButton="임시코드 받기"
            onSearch={onGetCode}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "비밀번호를 입력해 주세요." }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="비밀번호"
          />
        </Form.Item>
        <Form.Item
          name="passwordCheck"
          rules={[
            {
              required: true,
              message: "비밀번호를 한번 더 입력해 주세요.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("비밀번호가 일치하지 않습니다.")
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="비밀번호 확인"
          />
        </Form.Item>
        <Form.Item>
          <ResetButton
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            비밀번호 재설정
          </ResetButton>
        </Form.Item>
      </Form>

      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {isPending && <LoadingOverlay />}
    </Container>
  );
}

const Container = styled.div`
  width: 300px;
`;

const ResetButton = styled(Button)`
  width: 100%;
`;

export default PasswordResetForm;
