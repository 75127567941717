import { ContractDetailWithAccountDto } from "@/api";
import getDateTimeString from "@/utils/getDateTimeString";
import { TableProps } from "antd";

type UseContractsColumnsOptions = {
  data?: ContractDetailWithAccountDto[];
};

const useContractsColumns = ({ data }: UseContractsColumnsOptions) => {
  const dataSource = data?.map((obj, index) => ({
    ...obj,
    key: index,
    contractId: obj.contractId,
  }));

  const columns: TableProps["columns"] = [
    { title: "계좌유형", dataIndex: "accountTypeCode", key: "accountTypeCode" },
    {
      title: "계좌번호",
      dataIndex: "accountNo",
      key: "accountNo",
    },
    {
      title: "전략명",
      dataIndex: "strategyName",
      key: "strategyName",
    },
    {
      title: "상태",
      dataIndex: "accountStatusCode",
      key: "accountStatusCode",
    },
    {
      title: "원금",
      dataIndex: "principal",
      key: "principal",
      render: (text) => text.toLocaleString(),
    },
    {
      title: "평가잔고",
      dataIndex: "balance",
      key: "balance",
      render: (text) => text.toLocaleString(),
    },
    {
      title: "최초계약일",
      dataIndex: "initContractDate",
      key: "initContractDate",
      render: (date) =>
        getDateTimeString(date, {
          format: "YYYY-MM-DD",
          hideTime: true,
        }),
    },
    {
      title: "계약일",
      dataIndex: "contractDate",
      key: "contractDate",
      render: (date) =>
        getDateTimeString(date, {
          format: "YYYY-MM-DD",
          hideTime: true,
        }),
    },
  ];

  return { columns, dataSource };
};

export default useContractsColumns;
