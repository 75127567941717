import { gray, sub01, sub02, sub03, sub04, sub05 } from './baseColors';

const assetCategoryColors = {
  주식형: sub01.sub01_400,
  주식형Surface: sub01.sub01_200,

  채권형: sub02.sub02_400,
  채권형Surface: sub02.sub02_200,

  혼합형: sub03.sub03_400,
  혼합형Surface: sub03.sub03_200,

  대체투자: sub04.sub04_400,
  대체투자Surface: sub04.sub04_200,

  현금성자산: sub05.sub05_400,
  현금성자산Surface: sub05.sub05_200,

  개별주식종목: sub01.sub01_400,
  개별주식종목Surface: sub01.sub01_200,

  현금: gray.gray300,
  현금Surface: gray.gray100,
};

export default assetCategoryColors;
