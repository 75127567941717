import { useContext } from "react";
import * as styledComponents from "styled-components";

import normal, { FrismTheme } from "./normal";

const {
  default: styled,
  css,
  ThemeProvider,
  ThemeContext: StyledThemeContext,
} = styledComponents;

const ThemeContext = StyledThemeContext as unknown as React.Context<FrismTheme>;

export function useTheme() {
  return useContext<FrismTheme>(ThemeContext);
}

export { css, ThemeProvider, normal as normalTheme };

export * from "./normal";
export default styled;
