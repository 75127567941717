export const gray = {
  gray50: "rgba(244, 245, 247, 1)",
  gray100: "rgba(238, 239, 243, 1)",
  gray200: "rgba(209, 213, 224, 1)",
  gray300: "rgba(189, 194, 206, 1)",
  gray400: "rgba(122, 129, 144, 1)",
  gray500: "rgba(98, 105, 119, 1)",
  gray600: "rgba(73, 84, 96, 1)",
  gray700: "rgba(55, 66, 75, 1)",
  gray800: "rgba(37, 47, 52, 1)",
  gray900: "rgba(20, 27, 29, 1)",
};
export const primary = {
  primary100: "rgba(240, 245, 250, 1)",
  primary200: "rgba(204, 216, 239, 1)",
  primary300: "rgba(110, 129, 161, 1)",
  primary400: "rgba(20, 42, 79, 1)",
  primary500: "rgba(12, 14, 51, 1)",
};
export const sub01 = {
  sub01_100: "rgba(244, 247, 255, 1)",
  sub01_200: "rgba(227, 235, 255, 1)",
  sub01_300: "rgba(144, 168, 250, 1)",
  sub01_400: "rgba(71, 108, 236, 1)",
  sub01_500: "rgba(31, 68, 196, 1)",
};
export const sub02 = {
  sub02_100: "rgba(241, 253, 255, 1)",
  sub02_200: "rgba(218, 242, 254, 1)",
  sub02_300: "rgba(135, 211, 250, 1)",
  sub02_400: "rgba(56, 176, 236, 1)",
  sub02_500: "rgba(16, 136, 196, 1)",
};
export const sub03 = {
  sub03_100: "rgba(255, 251, 241, 1)",
  sub03_200: "rgba(255, 239, 220, 1)",
  sub03_300: "rgba(254, 196, 136, 1)",
  sub03_400: "rgba(241, 151, 57, 1)",
  sub03_500: "rgba(201, 111, 17, 1)",
};
export const sub04 = {
  sub04_100: "rgba(255, 245, 241, 1)",
  sub04_200: "rgba(251, 225, 219, 1)",
  sub04_300: "rgba(240, 158, 137, 1)",
  sub04_400: "rgba(220, 92, 60, 1)",
  sub04_500: "rgba(180, 52, 20, 1)",
};
export const sub05 = {
  sub05_100: "rgba(254, 245, 255, 1)",
  sub05_200: "rgba(252, 227, 252, 1)",
  sub05_300: "rgba(229, 167, 238, 1)",
  sub05_400: "rgba(188, 80, 205, 1)",
  sub05_500: "rgba(158, 50, 175, 1)",
};
export const positive = {
  positive100: "rgba(240, 255, 244, 1)",
  positive200: "rgba(215, 249, 223, 1)",
  positive300: "rgba(126, 223, 151, 1)",
  positive400: "rgba(52, 199, 89, 1)",
  positive500: "rgba(22, 168, 59, 1)",
};
export const negative = {
  negative100: "rgba(255, 241, 241, 1)",
  negative200: "rgba(254, 183, 183, 1)",
  negative300: "rgba(242, 119, 119, 1)",
  negative400: "rgba(227, 51, 51, 1)",
  negative500: "rgba(204, 46, 46, 1)",
};
export const neutral = {
  neutral300: "#CDD5DF",
  negative700: "#364152",
};
// NOTE: kangseongofdk
// 테마에 포함되지 않은 색상. 편의를 위해 임의로 추가함
export const tendencyNeutral = {
  tendencyNeutral100: "rgba(254, 246, 198, 1)",
  tendencyNeutral200: "rgba(254, 246, 198, 1)",
  tendencyNeutral300: "rgba(254, 228, 136, 1)",
  tendencyNeutral400: "rgba(204, 158, 0, 1)",
  tendencyNeutral500: "rgba(217, 168, 0, 1)",
};
export const shades = {
  white: "rgba(255, 255, 255, 1)",
  black: "rgba(0, 0, 0, 1)",
};
