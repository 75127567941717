import { useNavigate } from "react-router-dom";
import styled from "@/theme";
import EnvNameWhenNoProd from "@/components/EnvNameWhenNoProd";
import RegisterRequestForm from "./RegisterRequestForm";
import { Space, Typography } from "antd";

const { Title, Text } = Typography;

const RegisterPage = () => {
  const navigate = useNavigate();

  const onSuccess = () => {
    alert(
      "회원가입 요청이 완료되었습니다. 자세한 사항은 관리자에게 문의해주세요."
    );
    navigate("/login");
  };

  return (
    <Container>
      <EnvNameWhenNoProd />
      <Title level={3}>회원가입</Title>
      <Text>관리자에게 어드민 아이디 생성을 요청합니다.</Text>

      <Space direction="vertical" size={20} />

      <RegisterRequestForm onSuccess={onSuccess} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export default RegisterPage;
