import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import configureAppStore from "./configureAppStore";
import tokens from "@/tokens";

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [store, setStore] = useState<ReturnType<typeof configureAppStore>>();

  useEffect(() => {
    tokens.init();

    const isLoggedIn = !!tokens.accessToken;
    const newStore = configureAppStore({
      auth: {
        session: { isLoggedIn },
      },
    });

    setStore(newStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!store) {
    return <></>;
  }

  return <Provider store={store}>{children}</Provider>;
};

export default AppProvider;
