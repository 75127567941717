function getErrorMessageFromRes(err: unknown) {
  if (!err) {
    return null;
  }

  const responseException = (err as any).data?.responseException;
  if (responseException) {
    return responseException;
  }

  const errors: Record<string, string> | undefined = (err as any).data?.errors;
  if (errors) {
    return Object.values(errors)[0][0];
  }

  const status = (err as any).status;
  if (status === 403) {
    return '권한이 없습니다.';
  }

  return null;
}

export default getErrorMessageFromRes;
