import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import DownIcon from "@/assets/down.svg?react";
interface OptionType {
  label: string;
  value: string;
}

interface DropdownProps {
  label?: string;
  options: OptionType[];
  defaultValue?: string;
  onChange?: (value: string) => void;
}

const CommonDropdown: React.FC<DropdownProps> = ({
  label,
  options,
  defaultValue = "",
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (option: string) => {
    setSelectedValue(option);
    setIsOpen(false);
    onChange?.(option);
  };

  return (
    <Wrapper>
      <DropdownContainer ref={dropdownRef}>
        <DropdownButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          {label && <Label>{label}</Label>}

          {selectedValue
            ? options.find((opt) => opt.value === selectedValue)?.label
            : "선택하세요"}

          <StyledDownIcon isOpen={isOpen} />
        </DropdownButton>
        {isOpen && (
          <OptionsList>
            {options.map((option, index) => (
              <OptionItem
                key={index}
                onClick={() => handleSelect(option.value)}
                isSelected={option.value === selectedValue}
              >
                {option.label}
              </OptionItem>
            ))}
          </OptionsList>
        )}
      </DropdownContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.div`
  color: #7a8190;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  margin-right: 8px;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 42px;
  padding: 10px;
  background: white;
  border: 1px solid ${(p) => (p.isOpen ? "#7A8190" : "#D1D5E0")};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #476cec;

  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

const StyledDownIcon = styled(DownIcon)<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.2s ease;

  margin-left: 4px;
`;

const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 4px 0 0;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const OptionItem = styled.li<{ isSelected: boolean }>`
  padding: 10px;
  cursor: pointer;
  background: ${({ isSelected }) => (isSelected ? "#f0f0f0" : "white")};

  &:hover {
    background: #f5f5f5;
  }
`;

export default CommonDropdown;
