import { configureStore } from "@reduxjs/toolkit";

import rootReducer, { RootState } from "./rootReducer";

const configureAppStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    preloadedState,
  });

  return store;
};

export default configureAppStore;
export type AppDispatch = ReturnType<typeof configureAppStore>["dispatch"];
