import { useEffect } from "react";
import { AxiosResponse } from "axios";
import tokens from "@/tokens";
import lockUntilRenewToken from "./lockUntilRenewToken";
import { axiosInstance, CustomErrorType } from "../remoteStore";
import { axiosInstanceWithoutAuth } from "../remoteStore/mutator/customAxiosWithoutAuth";

type ErrorHandlerType = (err: CustomErrorType) => Promise<void>;

const useAxiosInterceptor = (
  onErrorWhileRenewToken: ErrorHandlerType,
  responseOnError: ErrorHandlerType
) => {
  const requestHandler = async (config: any) => {
    config.headers = {
      ...config.headers,
    };

    if (!tokens.accessToken) {
      return config;
    }

    await lockUntilRenewToken(onErrorWhileRenewToken);
    if (!tokens.accessToken) {
      /* FIXME: kunhee.lim
       *  Promise.reject() 를 하면 불필요한 요청을 막을 순 있지만
       *  useQuery 를 쓰는쪽에서 받는 에러가 불명확함.
       *  그리고 queryClient option의 retry 를 쓴다면 의미없어보임.
       *  어떻게 할지 고민이 필요함. */
      // return Promise.reject('access-token이 없습니다.');
    }

    const { Authorization, ...headers } = config.headers;

    const authorization =
      Authorization === ""
        ? {}
        : { Authorization: Authorization ?? `Bearer ${tokens.accessToken}` };

    return {
      ...config,
      headers: { ...headers, ...authorization },
    };
  };

  const requestInterceptor = axiosInstance.interceptors.request.use(
    requestHandler,
    (err) => Promise.reject(err)
  );

  const responseErrorHandler = async (err: CustomErrorType) => {
    await responseOnError(err);
    return Promise.reject(err);
  };

  const responseHandler = (response: AxiosResponse<any, any>) => response;

  const responseInterceptor = axiosInstance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => responseErrorHandler(error)
  );

  const responseInterceptorWithoutAuth =
    axiosInstanceWithoutAuth.interceptors.response.use(
      (response) => responseHandler(response),
      (err) => {
        return Promise.reject(err);
      }
    );

  useEffect(
    () => () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
      axiosInstanceWithoutAuth.interceptors.response.eject(
        responseInterceptorWithoutAuth
      );
    },
    [requestInterceptor, responseInterceptor, responseInterceptorWithoutAuth]
  );
};

export default useAxiosInterceptor;
