import envs from "@/envs";
import styled from "@/theme";

type EnvNameWhenNoProdProps = {};
function EnvNameWhenNoProd({ ...props }: EnvNameWhenNoProdProps) {
  if (envs.isProduction) {
    return null;
  }

  return <Container {...props}>{envs.envName}</Container>;
}

const Container = styled.span`
  font-size: 0.875rem;
  font-weight: 100;
  font-family: monospace;
`;

export default EnvNameWhenNoProd;
