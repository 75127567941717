import styled from "@/theme";
import { ReactNode } from "react";
import { keyframes } from "styled-components";

type ErrorMessageProps = {
  children?: ReactNode;
  text?: string;
};
function ErrorMessage({ children, text }: ErrorMessageProps) {
  const content = children ?? text;
  if (!content) {
    return null;
  }
  return <Container key={`${content}`}>{content}</Container>;
}

const fadeIn = keyframes`
  0% { 
    opacity: 1;
    max-height: 0;
  }
  20%{
    max-height: 100px;
  }
  100% { 
    opacity: 1;
    max-height: 100px;
  }
`;

const fadeOut = keyframes`
  100% { 
    opacity: 0;
    max-height: 0;
  }
`;

const Container = styled.p`
  color: ${(p) => p.theme.colors.negative400};
  animation:
    ${fadeIn} 2s,
    0.4s linear 5s ${fadeOut};
  animation-fill-mode: both;
  overflow: hidden;
`;

export default ErrorMessage;
