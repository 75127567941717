import { InvestmentSolicitorConsultationResDto } from "@/api";
import tokens from "@/tokens";
import getDateTimeString from "@/utils/getDateTimeString";
import { TableProps } from "antd/es/table";

type UseConsultationsColumnsOptions = {
  data?: InvestmentSolicitorConsultationResDto[];
};

const useConsultationsColumns = ({ data }: UseConsultationsColumnsOptions) => {
  const dataSource = data?.map((obj, index) => ({
    ...obj,
    key: index,
  }));

  const columns: TableProps["columns"] = [
    {
      title: "고객 이름",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "고객 전화번호",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "상담일시",
      dataIndex: "consultationUdtm",
      key: "consultationUdtm",
      render: (text) =>
        getDateTimeString(text, {
          format: "YYYY-MM-DD : HH시 mm분",
          hideTime: true,
        }),
    },
  ];

  if (!tokens.isInvestmentSolicitor) {
    columns.unshift({
      title: "투권인 ID",
      dataIndex: "solicitorId",
      key: "solicitorId",
    });
  }

  return { columns, dataSource };
};

export default useConsultationsColumns;
