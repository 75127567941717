import { ThemeProvider } from "styled-components";
import styled, { normalTheme } from "./theme";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import GlobalStyle from "./GlobalStyle";
import AxiosProvider from "./store/AxiosProvider";
import { QueryClientProvider } from "./api";
import { useAppSelector } from "./store";
import ErrorBoundary from "./ErrorBoundary";
import LoadingOverlay from "./components/LoadingOverlay";

const App = () => {
  const { loading } = useAppSelector((st) => st.status);
  
  return (
    <ErrorBoundary fallback={undefined}>
      <GlobalStyle />

      <QueryClientProvider>
        <AxiosProvider>
          <ThemeProvider theme={normalTheme}>
            <Container>
              <Outlet />
            </Container>
            <Toaster
              containerStyle={{
                fontFamily: "Pretendard-Medium",
                fontSize: 14,
              }}
              toastOptions={{
                duration: 3000,
              }}
            />
            {loading && <LoadingOverlay />}
          </ThemeProvider>
        </AxiosProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

const Container = styled.div`
  // background-color: red;
  // margin-top: 4rem; /* 네비게이션 바 높이만큼 여백 추가 */
`;

export default App;
