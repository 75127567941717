import { login, useAppDispatch, useAppSelector } from "@/store";
import styled from "@/theme";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import MfaForm from "./MfaForm";
import Modal from "@/components/Modal";
import EnvNameWhenNoProd from "@/components/EnvNameWhenNoProd";
import tokens from "@/tokens";
import toast from "react-hot-toast";
import { useImmer } from "use-immer";
import frismLogo from "@/assets/frism.png";

const LoginPage = () => {
  const { isLoggedIn } = useAppSelector((st) => st.auth.session);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  const [state, setState] = useImmer({
    email: "",
    session: "",
  });

  const dispatch = useAppDispatch();

  const onSuccessLogin = (email: string, session: string) => {
    setState((dr) => {
      dr.email = email;
      dr.session = session;
    });

    toast.success("인증번호가 발송되었습니다.");
  };

  const onCancelMfa = () => {
    setState((dr) => {
      dr.email = "";
      dr.session = "";
    });
  };

  const onSuccessMfa = async (accessToken: string, refreshToken: string) => {
    tokens.setTokens(accessToken, refreshToken);
    dispatch(login());
    navigate("/");
  };

  return (
    <Container>
      <InnerContainer>
        <EnvNameWhenNoProd />
        <FrismTitleImage src={frismLogo} alt="Logo" />

        <LoginForm onSuccess={onSuccessLogin} />

        <Modal
          showModal={!!state.email && !!state.session}
          onClose={onCancelMfa}
        >
          <ModalContent>
            <MfaForm
              email={state.email}
              session={state.session}
              onSuccess={onSuccessMfa}
            />
          </ModalContent>
        </Modal>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(p) => p.theme.dimens.spacing}px;

  width: 300px;
`;

const FrismTitleImage = styled.img`
  margin-bottom: ${(p) => p.theme.dimens.bigMargin}px;
  width: 120px;
`;

const ModalContent = styled.div`
  padding: 24px;
`;

export default LoginPage;
