import envs from "@/envs";
import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import { ApiResponse } from "../model";

export const axiosInstanceWithoutAuth = Axios.create({
  baseURL: envs.apiHost,
  timeout: 60 * 1000,
});

/* NOTE: kunhee.lim
 *  codegen 에서 사용하는 customAxios 외에 나머지 api 호출은 이 함수를 사용할것 */
export const customAxiosWithoutAuth = async <T>(
  config: AxiosRequestConfig
): Promise<T> => {
  const method = config.method ?? "GET";
  const url = config.url;

  console.log(method, url);

  if (!config.headers) {
    config.headers = {};
  }

  try {
    return axiosInstanceWithoutAuth({
      ...config,
    }).then((response) => response.data as T);
  } catch (error) {
    throw error;
  }
};

export class FrismErrorForRefresh<T> extends AxiosError<T> {}

export interface ErrorTypeForRefresh<Error>
  extends FrismErrorForRefresh<Error> {}

export type CustomErrorTypeForRefresh = FrismErrorForRefresh<ApiResponse>;
