import getTypographyStyle from "../getTypographyStyle";

// FIXME: kangseongofdk
// 좀 더 일반화
const baseText = {
  display1Bold: getTypographyStyle(32, 48, "bold"),
  display1Semibold: getTypographyStyle(32, 48, "semibold"),

  /* TODO: kunhee.lim
   *  h1, display에 extrabold 타입이 추가되었음 필요시 추가 해서 써야함 */
  h1ExtraBold: getTypographyStyle(24, 36, "extrabold"),
  h1Bold: getTypographyStyle(24, 36, "bold"),
  h1Semibold: getTypographyStyle(24, 36, "semibold"),

  h2Bold: getTypographyStyle(20, 32, "bold"),
  h2Semibold: getTypographyStyle(20, 32, "semibold"),

  h3Bold: getTypographyStyle(18, 29, "bold"),
  h3Semibold: getTypographyStyle(18, 29, "semibold"),
  h3Medium: getTypographyStyle(18, 29, "medium"),

  hSubRegular: getTypographyStyle(14, 22, "regular"),

  p1Bold: getTypographyStyle(16, 24, "bold"),
  p1Semibold: getTypographyStyle(16, 24, "semibold"),
  p1Medium: getTypographyStyle(16, 24, "medium"),
  p1Regular: getTypographyStyle(16, 24, "regular"),

  p2Bold: getTypographyStyle(15, 22, "bold"),
  p2Semibold: getTypographyStyle(15, 22, "semibold"),
  p2Medium: getTypographyStyle(15, 22, "medium"),
  p2Regular: getTypographyStyle(15, 22, "regular"),

  p3Bold: getTypographyStyle(14, 22, "bold"),
  p3Semibold: getTypographyStyle(14, 22, "semibold"),
  p3Medium: getTypographyStyle(14, 22, "medium"),
  p3Regular: getTypographyStyle(14, 22, "regular"),
  p3Article: getTypographyStyle(14, 27, "medium"),
  p3ArticleBold: getTypographyStyle(14, 27, "bold"),

  p4Bold: getTypographyStyle(13, 21, "bold"),
  p4Semibold: getTypographyStyle(13, 21, "semibold"),
  p4Medium: getTypographyStyle(13, 21, "medium"),
  p4Regular: getTypographyStyle(13, 21, "regular"),

  captionBold: getTypographyStyle(12, 20, "bold"),
  captionSemibold: getTypographyStyle(12, 20, "semibold"),
  captionMedium: getTypographyStyle(12, 20, "medium"),
  captionRegular: getTypographyStyle(12, 20, "regular"),

  caption2Bold: getTypographyStyle(10, 17, "bold"),
  caption2Semibold: getTypographyStyle(10, 17, "semibold"),
  caption2Medium: getTypographyStyle(10, 17, "medium"),
  caption2Regular: getTypographyStyle(10, 17, "regular"),
};
const text = {
  ...baseText,

  buttonNormal: baseText.p2Semibold,
  buttonSmall: baseText.p3Semibold,
  buttonSubNormal: baseText.p4Semibold,
  buttonSubTextNormal: baseText.p3Semibold,
  buttonSubSmall: baseText.captionSemibold,

  tabButtonNormal: baseText.captionMedium,
  tabButtonSelected: baseText.captionSemibold,
  tabMainButtonNormal: baseText.p4Medium,
  tabMainButtonSelected: baseText.p4Semibold,
  tabFilterButtonNormal: baseText.p4Medium,
  tabFilterButtonSelected: baseText.p4Bold,
  tabPageButtonNormal: baseText.p1Medium,
  tabPageButtonSelected: baseText.p1Semibold,

  tagDefault: baseText.captionSemibold,
  tagRound: baseText.captionSemibold,
  tagSmall: baseText.caption2Bold,
  tagExtraSmall: baseText.caption2Bold,

  chartXAxis: baseText.captionMedium,
  chartYAxis: baseText.captionMedium,

  /* NOTE: kunhee.lim
   * keypadKeys, keypadDesc, keypadDescWarning는 타입문제로 인해
   * fontFamily가 추가되었음. 추후 사용하는 화면에서 문제가 없는지 확인 요함.
   */
  keypadKeys: {
    ...baseText.h1Semibold,
  },
  keypadDesc: {
    ...baseText.p3Medium,
    color: "rgba(111, 119, 138, 1)",
  },
  keypadDescWarning: {
    ...baseText.p3Medium,
    color: "rgba(227, 62, 56, 1)",
  },
};

export default text;
