const FONTS = {
  extrabold: "Pretendard-Bold",
  bold: "Pretendard-SemiBold",
  semibold: "Pretendard-Medium",
  medium: "Pretendard-Regular",
  regular: "Pretendard-Light",
};

// TODO: kangseongofdk
// 인자가 하나 더 늘어나면 그냥 객체 하나로 받을 것
function getTypographyStyle(
  fontSize: number,
  lineHeight: number,
  fontWeight: keyof typeof FONTS
) {
  return {
    fontSize,
    lineHeight: `${lineHeight}px`,
    fontFamily: FONTS[fontWeight],
  };
}

export default getTypographyStyle;
