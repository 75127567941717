const envs = {
  get apiHost() {
    return import.meta.env.VITE_API_HOST;
  },
  get envName() {
    return import.meta.env.VITE_API_ENV_NAME ?? "unnamed";
  },
  get isProduction() {
    return this.envName === "production";
  },
  get frismTermsDetailUrl() {
    return "https://terms.frism.io";
  },
};

export default envs;
