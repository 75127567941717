import axios from "axios";
import envs from "@/envs";
import { useAppSelector } from "@/store";
import useSignOut from "./useSignOut";
import { CustomErrorType, TokenDtoApiResponse } from "../remoteStore";
import toast from "react-hot-toast";
import lockUntilRenewToken from "./lockUntilRenewToken";

const useAxiosInterceptorCallBacks = () => {
  const { isLoggedIn } = useAppSelector((st) => st.auth.session);

  const { signOut: _signOut } = useSignOut();

  const signOut = async (err: CustomErrorType) => {
    console.log("로그아웃 : ", err.response);
    await _signOut();

    const response = err.response as TokenDtoApiResponse;
    console.log("response", response);

    toast.error("로그아웃 되었습니다.");
  };

  const alertError = (status: number | undefined, err: CustomErrorType) => {
    console.error(
      err,
      `\n response.data: ${JSON.stringify(err.response?.data ?? {}, null, 2)},\n` +
        `axiosResponseError: ${JSON.stringify(err, null, 2)}`
    );

    if (!envs.isProduction) {
      toast.error(
        `${status}: ${err.response?.data?.responseException || err.message}`
      );
      return;
    }

    if ((status ?? 0) >= 500) {
      toast.error("잠시 후 다시 시도해주세요.");
      return;
    }

    if (err.response?.data?.responseException) {
      toast.error(`${err.response.data.responseException}`);
      return;
    }
  };

  const onErrorWhileRenewAccessToken = async (err: CustomErrorType) => {
    const status = err.response?.status;

    if (status === 200 || status === 401 || status === 403) {
      await signOut(err);
      return;
    }

    alertError(status, err);
  };

  const responseOnError = async (err: CustomErrorType) => {
    console.log("에러 요기");
    if (err.code === "ERR_CANCELED") {
      /* NOTE: kunhee.lim
       *  react-query signal canceled 인 경우 */
      return;
    }
    const status = err.response?.status;

    if (status === 401) {
      /* NOTE: kunhee.lim
       *  이 분기를 타는 경우 네트워크 지연 또는 vpn 연결 끊김으로
       *  request 가 느리게 전달되었거나 예상치 못한경우임(흔하진 않음) */
      if (isLoggedIn) {
        /* NOTE: kunhee.lim
         *  로그인이 되지 않은상태에서는 토큰재발급 할 필요 없음.
         *  본인인증 쪽에서 /api/users/profile 가 호출되는 케이스가 있어서 막아둠 */
        await lockUntilRenewToken(onErrorWhileRenewAccessToken);
      }
      return;
    }

    if (status !== 555 && status !== 556) {
      /* NOTE: kunhee.lim
       *  서버점검은 Sentry 에러 쏘지 않기 */
      if (axios.isAxiosError(err)) {
        if (err.response) {
          // 서버 응답이 2xx 범위를 벗어난 상태 코드를 반환
          console.log(
            "Response error:",
            err.response.status,
            err.response.data
          );
        } else if (err.request) {
          // 요청이 전송되었지만 응답을 받지 못함
          console.log("Request error:", err.request);
        } else {
          // 요청 설정 중 오류 발생
          console.log("Error:", err.message);
        }
      } else {
        console.log("Unknown error:", err);
      }
    }
    alertError(status, err);
  };

  return { onErrorWhileRenewAccessToken, responseOnError };
};

export default useAxiosInterceptorCallBacks;
