import { usePostAdminUserSignOut } from "@/api";
import { logout, useAppDispatch } from "@/store";
import { useAppSelector } from "@/store";
import tokens from "@/tokens";
import {
  MenuFoldOutlined,
  CalendarOutlined,
  UserOutlined,
  FormOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { HomeOutlined } from "@ant-design/icons";
import { Button, Layout, Menu, MenuProps, theme } from "antd";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import Consultations from "./Consultations";
import Customers from "./Customers";
import Reservations from "./Reservations";

const { Header, Sider, Content } = Layout;

const Home = () => {
  const [state, setState] = useImmer({
    collapsed: false,
    menu: "consultations",
  });
  const { isLoggedIn } = useAppSelector((st) => st.auth.session);

  const { mutateAsync: signOut } = usePostAdminUserSignOut();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoggedIn === false) {
      navigate("/login");
    }
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const onClickLogout = async () => {
    try {
      await signOut();
    } catch (err) {
    } finally {
      tokens.clearTokens();
      dispatch(logout());
      navigate("/login");
      toast.success("로그아웃 되었습니다.");
    }
  };

  const onClickMenu: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setState((dr) => {
      dr.menu = e.key;
    });
  };

  return (
    <Layout hasSider>
      <Sider trigger={null} collapsible collapsed={state.collapsed}>
        <div className="demo-logo-vertical" />
        <Menu
          mode="inline"
          onClick={onClickMenu}
          theme="light"
          style={{ height: "100%" }}
          defaultSelectedKeys={["consultations"]}
          items={[
            {
              key: "home",
              icon: <HomeOutlined />,
              label: "홈",
            },

            {
              key: "consultations",
              icon: <FormOutlined />,
              label: "상담 이력",
            },
            {
              key: "customers",
              icon: <UserOutlined />,
              label: "고객 목록",
            },
            {
              key: "reservations",
              icon: <CalendarOutlined />,
              label: "예약 목록",
            },
            {
              type: "divider",
            },
            {
              key: "logout",
              icon: <LogoutOutlined />,
              label: "로그아웃",
              onClick: onClickLogout,
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Button
            type="text"
            icon={
              state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
            }
            onClick={() =>
              setState((dr) => {
                dr.collapsed = !dr.collapsed;
              })
            }
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {state.menu === "home" ? (
            <Home />
          ) : state.menu === "consultations" ? (
            <Consultations />
          ) : state.menu === "customers" ? (
            <Customers />
          ) : state.menu === "reservations" ? (
            <Reservations />
          ) : (
            <></>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
