import envs from "@/envs";

import { AxiosError } from "axios";
import tokens from "@/tokens";
import {
  customAxiosWithoutAuth,
  CustomErrorTypeForRefresh,
} from "../remoteStore/mutator/customAxiosWithoutAuth";
import { TokenDtoApiResponse } from "../remoteStore";

async function lockUntilRenewToken(
  onErrorWhileRenewAccessToken: (err: CustomErrorTypeForRefresh) => void
) {
  const now = new Date().getTime();
  const expired = tokens.accessTokenExpired ?? 0;

  if (now + 10 * 1000 > expired) {
    try {
      const res = await customAxiosWithoutAuth<TokenDtoApiResponse>({
        method: "post",
        baseURL: envs.apiHost,
        url: "/admin/user/refresh",
        data: {
          email: tokens.email,
          refreshToken: tokens.refreshToken,
        },
      });

      if (!res.result?.accessToken) {
        throw new AxiosError(
          res.statusCode + "",
          undefined,
          undefined,
          undefined
        );
      }
      await tokens.setTokens(res.result.accessToken, tokens.refreshToken);
    } catch (err) {
      if (err instanceof AxiosError) {
        await onErrorWhileRenewAccessToken(err);
      } else {
        console.error("error in axiosInterceptor :", err);
      }
    }
  }
}

export default lockUntilRenewToken;
