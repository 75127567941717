import {
  gray,
  negative,
  positive,
  primary,
  shades,
  sub01,
  sub02,
  sub03,
  sub04,
  sub05,
  tendencyNeutral,
} from './baseColors';

// NOTE: kangseongofdk
// 오버 엔지니어링 의심 중
const tagColors = {
  tagSolidPrimarySurface: primary.primary400,
  tagSolidPrimaryText: shades.white,
  tagSolidSub01Surface: sub01.sub01_400,
  tagSolidSub01Text: shades.white,
  tagSolidSub02Surface: sub02.sub02_400,
  tagSolidSub02Text: shades.white,
  tagSolidSub03Surface: sub03.sub03_400,
  tagSolidSub03Text: shades.white,
  tagSolidSub04Surface: sub04.sub04_400,
  tagSolidSub04Text: shades.white,
  tagSolidSub05Surface: sub05.sub05_400,
  tagSolidSub05Text: shades.white,
  tagSolidNeutralSurface: gray.gray400,
  tagSolidNeutralText: shades.white,
  tagSolidPositiveSurface: positive.positive400,
  tagSolidPositiveText: shades.white,
  tagSolidNegativeSurface: negative.negative400,
  tagSolidNegativeText: shades.white,
  // tagSolidTendencyNeutralSurface: tendencyNeutral.tendencyNeutral400,
  // tagSolidTendencyNeutralText: shades.white,

  tagMediumPrimarySurface: primary.primary200,
  tagMediumPrimaryText: primary.primary500,
  tagMediumSub01Surface: sub01.sub01_200,
  tagMediumSub01Text: sub01.sub01_500,
  tagMediumSub02Surface: sub02.sub02_200,
  tagMediumSub02Text: sub02.sub02_500,
  tagMediumSub03Surface: sub03.sub03_200,
  tagMediumSub03Text: sub03.sub03_500,
  tagMediumSub04Surface: sub04.sub04_200,
  tagMediumSub04Text: sub04.sub04_500,
  tagMediumSub05Surface: sub05.sub05_200,
  tagMediumSub05Text: sub05.sub05_500,
  tagMediumNeutralSurface: gray.gray100,
  tagMediumNeutralText: gray.gray600,
  tagMediumPositiveSurface: positive.positive200,
  tagMediumPositiveText: positive.positive500,
  tagMediumNegativeSurface: negative.negative200,
  tagMediumNegativeText: negative.negative400,
  tagMediumTendencyNeutralSurface: tendencyNeutral.tendencyNeutral200,
  tagMediumTendencyNeutralText: tendencyNeutral.tendencyNeutral400,

  tagLightPrimarySurface: primary.primary100,
  tagLightPrimaryText: primary.primary400,
  tagLightSub01Surface: sub01.sub01_100,
  tagLightSub01Text: sub01.sub01_400,
  tagLightSub02Surface: sub02.sub02_100,
  tagLightSub02Text: sub02.sub02_400,
  tagLightSub03Surface: sub03.sub03_100,
  tagLightSub03Text: sub03.sub03_400,
  tagLightSub04Surface: sub04.sub04_100,
  tagLightSub04Text: sub04.sub04_400,
  tagLightSub05Surface: sub05.sub05_100,
  tagLightSub05Text: sub05.sub05_400,
  tagLightNeutralSurface: gray.gray50,
  tagLightNeutralText: gray.gray400,
  tagLightPositiveSurface: positive.positive100,
  tagLightPositiveText: positive.positive400,
  tagLightNegativeSurface: negative.negative100,
  tagLightNegativeText: negative.negative400,
  tagLightTendencyNeutralSurface: tendencyNeutral.tendencyNeutral100,
  tagLightTendencyNeutralText: tendencyNeutral.tendencyNeutral400,

  tagPrimarySurface: primary.primary100,
  tagPrimaryText: primary.primary500,
  tagSub01Surface: sub01.sub01_100,
  tagSub01Text: sub01.sub01_400,
  tagSub02Surface: sub02.sub02_100,
  tagSub02Text: sub02.sub02_400,
  tagSub03Surface: sub03.sub03_100,
  tagSub03Text: sub03.sub03_400,
  tagSub04Surface: sub04.sub04_100,
  tagSub04Text: sub04.sub04_400,
  tagSub05Surface: sub05.sub05_100,
  tagSub05Text: sub05.sub05_400,
  tagNeutralSurface: gray.gray50,
  tagNeutralText: gray.gray400,
  tagPositiveSurface: positive.positive100,
  tagPositiveText: positive.positive400,
  tagNegativeSurface: negative.negative100,
  tagNegativeText: negative.negative400,
  tagTendencyNeutralSurface: tendencyNeutral.tendencyNeutral100,
  tagTendencyNeutralText: tendencyNeutral.tendencyNeutral400,

  tagSmallSolidIncreaseSurface: negative.negative400,
  tagSmallSolidIncreaseText: shades.white,
  tagSmallSolidDecreaseSurface: sub01.sub01_400,
  tagSmallSolidDecreaseText: shades.white,
  tagSmallSolidNeutralSurface: primary.primary400,
  tagSmallSolidNeutralText: shades.white,

  tagSmallSolidSub01Surface: sub01.sub01_400,
  tagSmallSolidSub01Text: shades.white,

  tagSmallMediumNeutralSurface: gray.gray100,
  tagSmallMediumNeutralText: gray.gray600,

  tagSmallLightPrimarySurface: primary.primary100,
  tagSmallLightPrimaryText: primary.primary400,
  tagSmallLightSub01Surface: sub01.sub01_100,
  tagSmallLightSub01Text: sub01.sub01_400,
  tagSmallLightSub02Surface: sub02.sub02_100,
  tagSmallLightSub02Text: sub02.sub02_400,
  tagSmallLightSub03Surface: sub03.sub03_100,
  tagSmallLightSub03Text: sub03.sub03_400,
  tagSmallLightSub04Surface: sub04.sub04_100,
  tagSmallLightSub04Text: sub04.sub04_400,
  tagSmallLightNeutralSurface: gray.gray50,
  tagSmallLightNeutralText: gray.gray400,
  tagSmallLightPositiveSurface: positive.positive100,
  tagSmallLightPositiveText: positive.positive400,
  tagSmallLightNegativeSurface: negative.negative100,
  tagSmallLightNegativeText: negative.negative400,
  tagSmallLightTendencyNeutralSurface: tendencyNeutral.tendencyNeutral100,
  tagSmallLightTendencyNeutralText: tendencyNeutral.tendencyNeutral400,

  tagSmallIncreaseSurface: negative.negative100,
  tagSmallIncreaseText: negative.negative500,
  tagSmallDecreaseSurface: sub01.sub01_100,
  tagSmallDecreaseText: sub01.sub01_500,
  tagSmallNeutralSurface: primary.primary100,
  tagSmallNeutralText: primary.primary500,

  tagExtraSmallLightPrimarySurface: primary.primary100,
  tagExtraSmallLightPrimaryText: primary.primary400,
  tagExtraSmallLightSub01Surface: sub01.sub01_100,
  tagExtraSmallLightSub01Text: sub01.sub01_400,
  tagExtraSmallLightSub02Surface: sub02.sub02_100,
  tagExtraSmallLightSub02Text: sub02.sub02_400,
  tagExtraSmallLightSub03Surface: sub03.sub03_100,
  tagExtraSmallLightSub03Text: sub03.sub03_400,
  tagExtraSmallLightSub04Surface: sub04.sub04_100,
  tagExtraSmallLightSub04Text: sub04.sub04_400,
  tagExtraSmallLightNeutralSurface: gray.gray50,
  tagExtraSmallLightNeutralText: gray.gray400,
  tagExtraSmallLightPositiveSurface: positive.positive100,
  tagExtraSmallLightPositiveText: positive.positive400,
  tagExtraSmallLightNegativeSurface: negative.negative100,
  tagExtraSmallLightNegativeText: negative.negative400,
  tagExtraSmallLightTendencyNeutralSurface: tendencyNeutral.tendencyNeutral100,
  tagExtraSmallLightTendencyNeutralText: tendencyNeutral.tendencyNeutral400,

  tagExtraSmallMediumSub01Surface: sub01.sub01_200,
  tagExtraSmallMediumSub01Text: sub01.sub01_500,

  tagExtraSmallMediumNeutralSurface: gray.gray100,
  tagExtraSmallMediumNeutralText: gray.gray600,
};

export default tagColors;
