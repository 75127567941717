import dayjs from "dayjs";

export type GetDateTimeStringOptions = {
  hideTime?: boolean;
  format?:
    | "YY/MM/DD"
    | "YYYY년 MM월 DD일"
    | "YYYY-MM-DD"
    | "YYYY-MM-DD HH:mm:ss"
    | "YYYY-MM-DD : HH시 mm분";
};
function getDateTimeString(
  date: Date | string | null | undefined,
  options: GetDateTimeStringOptions = {}
) {
  if (!date) {
    return undefined;
  }

  const format = options?.format ?? "YY/MM/DD";

  if (options.hideTime) {
    return dayjs(date).format(format);
  }
  return dayjs(date).format(`${format} HH:mm:ss`);
}

export default getDateTimeString;
