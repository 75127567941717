import { createSlice } from '@reduxjs/toolkit';

type StatusState = {
  loading: boolean;
};

const initialState: StatusState = {
  loading: false,
};
export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    showLoading: state => {
      state.loading = true;
    },
    hideLoading: state => {
      state.loading = false;
    },
  },
});

export const { showLoading, hideLoading } = statusSlice.actions;

const statusReducer = statusSlice.reducer;
export default statusReducer;
