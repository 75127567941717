import { Table } from "antd";
import {
  ConsultingTypeCode,
  ContractDetailWithAccountDto,
  ReservationConfirmedResDto,
  ReservationWaitingResDto,
  SortCriteriaCode,
  SortingOrderCode,
  usePatchAdminReservationConsultingReservationIdAccept,
  usePostAdminReservationChanged,
  usePostAdminReservationConfirmed,
  usePostAdminReservationWaiting,
} from "@/api";
import { useImmer } from "use-immer";
import useAsyncEffect from "@/utils/useAsyncEffect";
import ResModal from "./ResModal";
import styled from "@/theme";
import useReservationColumns from "./useReservationColumns";
import Search from "./Search";
import CommonDropdown from "@/components/CommonDropdown";
import toast from "react-hot-toast";
import tokens from "@/tokens";

const Reservations = () => {
  const [state, setState] = useImmer({
    tab: "예약 확정" as "예약 확정" | "예약 대기중" | "변경/취소 내역",
    page: 1,
    size: 15,
    data: [] as
      | ReservationWaitingResDto[]
      | ReservationConfirmedResDto[]
      | undefined,
    crmCustomerName: undefined as string | undefined,
    reservationId: undefined as number | undefined,

    totalCount: 0 as number | undefined,
    contractDetailWithAccountList: undefined as
      | ContractDetailWithAccountDto[]
      | undefined,
    showModal: false,
    consultingType: "전체" as ConsultingTypeCode,
    sortCriteriaCode: "예약일자" as SortCriteriaCode,
    sortingOrderCode: "Ascending" as SortingOrderCode,
  });

  const isInvestmentSolicitorAdmin = tokens.isInvestmentSolicitorAdmin;

  const fetchData = async () => {
    const apiCall =
      state.tab === "예약 확정"
        ? postReservationConfirmed
        : state.tab === "예약 대기중"
          ? postReservationWaiting
          : postReservationChanged;

    const { result } = await apiCall({
      params: {
        PageNum: state.page,
        PageSize: state.size,
        SortCriteriaCode: state.sortCriteriaCode as SortCriteriaCode,
        SortingOrderCode: state.sortingOrderCode as SortingOrderCode,
      },
      data: {
        consultingTypeCode: state.consultingType,
        crmCustomerName:
          state.crmCustomerName && state.crmCustomerName.length > 0
            ? state.crmCustomerName
            : undefined,
      },
    });
    setState((dr) => {
      if (state.tab === "예약 확정") {
        dr.data = result?.rows as unknown as ReservationWaitingResDto[];
      } else {
        dr.data = result?.rows as ReservationWaitingResDto[];
      }
    });

    console.log("result", result?.rows);
  };

  const { mutateAsync: postReservationWaiting } =
    usePostAdminReservationWaiting();

  const { mutateAsync: postReservationConfirmed } =
    usePostAdminReservationConfirmed();

  const { mutateAsync: postReservationChanged } =
    usePostAdminReservationChanged();

  const { mutateAsync: patchAccept } =
    usePatchAdminReservationConsultingReservationIdAccept({
      mutation: {
        onSuccess: async () => {
          toast.success("상담 승인이 완료되었습니다.");
          await fetchData();
        },
        onError: () => {
          toast.error("문제가 발생하였습니다. 잠시 후 다시 시도해주세요.");
        },
      },
    });

  useAsyncEffect(async () => {
    fetchData();
  }, [
    state.tab, // tab 상태 변경 시에도 API 호출
    state.consultingType,
    state.sortCriteriaCode,
    state.sortingOrderCode,
    state.crmCustomerName,
  ]);

  const onClickDetail = (reservationId: number) => {
    setState((dr) => {
      dr.reservationId = reservationId;
      dr.showModal = true;
    });
  };

  const { columns, dataSource, ApprovalModal, StatusModal } =
    useReservationColumns({
      data: state.data,
      type: state.tab === "예약 확정" ? "confirmed" : "waiting",
      isInvestmentSolicitorAdmin,
      onClickDetail,
      onApproveConsulting: async (id, params) => {
        try {
          await patchAccept({ consultingReservationId: id, data: params });
          // 성공 후 데이터 리로드 로직
        } catch (error) {
          console.error("상담 승인 실패", error);
        }
      },
    });

  const onChangePage = (page: number, size: number) => {
    setState((dr) => {
      dr.page = page;
      dr.size = size;
    });
  };

  const handleSearch = async (customerName: string) => {
    setState((dr) => {
      dr.crmCustomerName = customerName;
    });
  };

  const consultingTypeOptions: { label: string; value: string }[] = [
    { label: "전체", value: "전체" },
    { label: "대면상담", value: "대면상담" },
    { label: "유선상담", value: "유선상담" },
    { label: "VIP상담", value: "VIP상담" },
  ];

  const sortCriteriaOptions: { label: string; value: string }[] = [
    { label: "예약일자", value: "예약일자" },
    { label: "접수일자", value: "접수일자" },
    { label: "선택안함", value: "선택안함" },
  ];

  const sortingOrderOptions: { label: string; value: string }[] = [
    { label: "최신순", value: "Ascending" },
    { label: "오래된순", value: "Descending" },
  ];

  return (
    <Container>
      <TableWrapper>
        <TabContainer>
          <TabItem
            isActive={state.tab === "예약 확정"}
            onClick={() =>
              setState((dr) => {
                dr.tab = "예약 확정";
              })
            }
          >
            예약 확정
          </TabItem>
          <TabItem
            isActive={state.tab === "예약 대기중"}
            onClick={() =>
              setState((dr) => {
                dr.tab = "예약 대기중";
              })
            }
          >
            예약 대기중
          </TabItem>
          {isInvestmentSolicitorAdmin && (
            <TabItem
              isActive={state.tab === "변경/취소 내역"}
              onClick={() =>
                setState((dr) => {
                  dr.tab = "변경/취소 내역";
                })
              }
            >
              변경/취소 내역
            </TabItem>
          )}
        </TabContainer>
        <Header>
          <Search value={state.crmCustomerName} onSearch={handleSearch} />

          <CommonDropdown
            defaultValue={state.consultingType}
            onChange={(value) =>
              setState((draft) => {
                draft.consultingType = value as ConsultingTypeCode;
              })
            }
            options={consultingTypeOptions}
            label="상담유형"
          />
          <CommonDropdown
            defaultValue={state.sortCriteriaCode}
            onChange={(value) =>
              setState((draft) => {
                draft.sortCriteriaCode = value as SortCriteriaCode;
              })
            }
            options={sortCriteriaOptions}
          />
          <CommonDropdown
            defaultValue={state.sortingOrderCode}
            onChange={(value) =>
              setState((draft) => {
                draft.sortingOrderCode = value as SortingOrderCode;
              })
            }
            options={sortingOrderOptions}
          />
        </Header>
        <Table
          columns={columns}
          dataSource={dataSource}
          className="centered-table"
          scroll={{ y: "calc(100vh - 300px)" }}
          pagination={{
            pageSize: state.size,
            total: state.totalCount,
            current: state.page,
            onChange: onChangePage,
            pageSizeOptions: ["15", "30", "50", "100"],
          }}
        />
      </TableWrapper>

      <ResModal
        id={state.reservationId}
        showModal={state.showModal}
        onClose={() =>
          setState((dr) => {
            dr.showModal = false;
          })
        }
      />
      {ApprovalModal}
      {StatusModal}
    </Container>
  );
};

const TabContainer = styled.div`
  align-items: center;

  display: flex;
  gap: 24px;

  margin-bottom: 40px;
`;

const TabItem = styled.div<{ isActive: boolean }>`
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  color: ${({ isActive }) => (isActive ? "#1F2633" : "#7A8190")};

  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  align-self: flex-start;

  margin-bottom: 40px;

  gap: 12px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .centered-table {
    .ant-table-cell {
      text-align: center;
    }
  }
`;

export default Reservations;
