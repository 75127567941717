import styled from "@/theme";
import Image404 from "@/assets/404.png";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/");
  };
  return (
    <Container>
      <Image src={Image404} />
      <Title>페이지를 찾을 수 없습니다</Title>
      <Content>
        {
          "찾으려는 페이지의 주소가 잘 못 입력되었거나,\n주소의 변경 혹은 삭제로인해 사용할 수 없습니다.\n\n입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요."
        }
      </Content>
      <Button onClick={onClick}>메인 홈으로</Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: calc(100vh - 110px);

  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
`;

const Title = styled.div`
  ${(p) => p.theme.text.h1Semibold}

  margin-top: 32px;
`;

const Content = styled.div`
  margin-top: 12px;
  ${(p) => p.theme.text.p2Regular};
  color: ${(p) => p.theme.colors.gray700};
  white-space: pre-wrap;

  text-align: center;
`;

const Button = styled.div`
  display: flex;
  padding: 10px 16px;
  margin-top: 32px;
  margin-bottom: 110px;
  justify-content: center;
  align-items: center;
  justify-self: center;

  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  background-color: #2b653b;
  &:hover {
    background-color: #004225;
  }
  color: #ffffff;
  cursor: pointer;

  ${(p) => p.theme.text.p2Medium};
`;

export default NotFound;
