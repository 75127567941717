export const formatPhoneNumber = (phone?: string) => {
  if (!phone) return "";

  // 숫자만 추출
  const numbers = phone.replace(/[^\d]/g, "");

  // 11자리 전화번호 포맷팅 (010-XXXX-XXXX)
  if (numbers.length === 11) {
    return numbers.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }

  // 그 외의 경우 원본 반환
  return phone;
};
