import { ReactNode } from "react";
import styled from "styled-components";

export interface ModalProps {
  showModal: boolean;
  onClose: () => void;
}

const Modal = ({
  showModal,
  onClose,
  children,
}: ModalProps & { children: ReactNode }) => {
  if (!showModal) return null;

  return (
    <ModalBackground onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalBackground>
  );
};

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  max-height: 90vh;
`;

export default Modal;
