import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled, { css } from "@/theme";

export type LoadingOverlayProps = {
  overlayColor?: string;
  indicatorSize?: number;
};
function LoadingOverlay({
  overlayColor,
  indicatorSize = 40,
}: LoadingOverlayProps) {
  return (
    <Overlay overlayColor={overlayColor}>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: indicatorSize }} spin />}
      />
    </Overlay>
  );
}

type OverlayProps = {
  overlayColor?: string;
};
const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.3);
  ${(p) =>
    !!p.overlayColor &&
    css`
      background-color: ${p.overlayColor};
    `}
`;

export default LoadingOverlay;
