import {
  ConsultingOptionsParams,
  useGetAdminReservationConsultantInfo,
  useGetAdminReservationConsultingAddress,
} from "@/api";
import styled from "@/theme";
import { Button, Input, Modal, Select } from "antd";
import { useState } from "react";
import Space from "./Space";

interface ConsultingApprovalModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (params: ConsultingOptionsParams) => void;
}

const ConsultingApprovalModal = ({
  isOpen,
  onClose,
  onConfirm,
}: ConsultingApprovalModalProps) => {
  const { data: consultantData } = useGetAdminReservationConsultantInfo();
  const { data: addressData } = useGetAdminReservationConsultingAddress();

  const [selectedConsultant, setSelectedConsultant] = useState<string>();
  const [selectedAddress, setSelectedAddress] = useState<number>();

  const handleConfirm = () => {
    if (!selectedConsultant) return;

    onConfirm({
      consultantId: selectedConsultant,
      consultingAddressId: selectedAddress,
    });

    onClose();
  };

  const consultantOptions = consultantData?.result?.options?.map((item) => ({
    label: item.consultantName,
    value: item.consultantId,
  }));

  const addressOptions = addressData?.result?.options?.map((item) => ({
    label: item.meetingAddress.split("\\n")[0],
    value: item.consultingAddressId,
  }));

  const subAddress = addressData?.result?.options
    ?.find((item) => item.consultingAddressId === selectedAddress)
    ?.meetingAddress.split("\\n")[1];

  const phoneNumber = consultantData?.result?.options?.find(
    (item) => item.consultantId === selectedConsultant
  )?.consultantPhoneNumber;

  console.log("consultantData", consultantData);

  return (
    <Modal open={isOpen} onCancel={onClose} centered footer={null} width={400}>
      <Container>
        <Title>상담 승인</Title>
        <Description>상담사의 연락처 및 상담 장소를 확인해 주세요.</Description>

        <SelectDesc>상담사</SelectDesc>
        <Select
          placeholder="상담사를 선택해주세요"
          style={{ width: "100%" }}
          onChange={(value) => setSelectedConsultant(value)}
          options={consultantOptions}
        />

        <Space y={6} />

        <Input disabled value={phoneNumber} />

        <Space y={32} />

        <SelectDesc>상담 장소</SelectDesc>
        <Select
          placeholder="장소를 선택해주세요"
          style={{ width: "100%" }}
          onChange={(value) => setSelectedAddress(value)}
          options={addressOptions}
        />

        <Space y={6} />

        <Input disabled value={subAddress} />

        <Space y={12} />

        <ButtonContainer>
          <Button onClick={onClose}>취소</Button>

          <Button
            disabled={!selectedConsultant || !selectedAddress}
            type="primary"
            onClick={handleConfirm}
          >
            확인
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectDesc = styled.div`
  color: var(--Gray-Gray-400, #7a8190);
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */

  margin-bottom: 3px;

  align-self: flex-start;
`;

const Title = styled.div`
  color: var(--Color-Dark-Dark, #23262f);
  text-align: center;

  ${(p) => p.theme.text.h2Bold}

  margin-top: 12px;
`;

const Description = styled.div`
  color: var(--Gray-Gray-400, #7a8190);
  text-align: center;

  ${(p) => p.theme.text.p3Medium}

  margin: 12px 0 32px;
`;

export default ConsultingApprovalModal;
