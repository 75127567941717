import { createSlice } from "@reduxjs/toolkit";

export interface AuthSliceState {
  session: {
    isLoggedIn: boolean;
  };
}

const initialState: AuthSliceState = {
  session: {
    isLoggedIn: false,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.session.isLoggedIn = true;
    },
    logout: (state) => {
      state.session.isLoggedIn = false;
    },
  },
});

export const { login, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;

export default authSlice;
