/**
 * Do not edit manually.
 * Frism Admin Server
 * OpenAPI spec version: admin
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AccountListResDtoApiResponse,
  AccountTransactionResDtoApiResponse,
  AccountTransferProceedingResDtoApiResponse,
  ContractDetailWithAccountResDtoApiResponse,
  ContractHistoryResDtoApiResponse,
  ContractSummaryResDtoListApiResponse,
  CustomerBaseReqDto,
  CustomerCoachingListDtoApiResponse,
  CustomerInfoReqDto,
  FeeAccountSummaryResDtoListApiResponse,
  GetAdminInvestmentSolicitorConsultationsParams,
  GetAdminInvestmentSolicitorConsultationsSearchParams,
  GetAdminInvestmentSolicitorUsersParams,
  GetAdminInvestmentSolicitorUsersSearchParams,
  InvestmentPropensityResDtoListApiResponse,
  InvestmentSolicitorConsultationPageDtoApiResponse,
  InvestmentSolicitorUserPageDtoApiResponse,
  PortfolioDetailDtoApiResponse,
  PostAdminInvestmentSolicitorAccountTransactionsParams,
  PostAdminInvestmentSolicitorCoachingsParams,
} from "../../model";
import { customAxios } from "../../mutator/customAxios";
import type { ErrorType } from "../../mutator/customAxios";

/**
 * @summary 상담 이력 리스트 조회
 */
export const getAdminInvestmentSolicitorConsultations = (
  params?: GetAdminInvestmentSolicitorConsultationsParams,
  signal?: AbortSignal,
) => {
  return customAxios<InvestmentSolicitorConsultationPageDtoApiResponse>({
    url: `/admin/investment-solicitor/consultations`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetAdminInvestmentSolicitorConsultationsQueryKey = (
  params?: GetAdminInvestmentSolicitorConsultationsParams,
) => {
  return [
    `/admin/investment-solicitor/consultations`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetAdminInvestmentSolicitorConsultationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminInvestmentSolicitorConsultations>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAdminInvestmentSolicitorConsultationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAdminInvestmentSolicitorConsultations>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAdminInvestmentSolicitorConsultationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminInvestmentSolicitorConsultations>>
  > = ({ signal }) => getAdminInvestmentSolicitorConsultations(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminInvestmentSolicitorConsultations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminInvestmentSolicitorConsultationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminInvestmentSolicitorConsultations>>
>;
export type GetAdminInvestmentSolicitorConsultationsQueryError =
  ErrorType<unknown>;

/**
 * @summary 상담 이력 리스트 조회
 */
export const useGetAdminInvestmentSolicitorConsultations = <
  TData = Awaited<ReturnType<typeof getAdminInvestmentSolicitorConsultations>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAdminInvestmentSolicitorConsultationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAdminInvestmentSolicitorConsultations>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAdminInvestmentSolicitorConsultationsQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 상담 이력 중 프리즘 기고객 및 전환 고객 조회
 * @summary 상담 고객 조회
 */
export const getAdminInvestmentSolicitorUsers = (
  params?: GetAdminInvestmentSolicitorUsersParams,
  signal?: AbortSignal,
) => {
  return customAxios<InvestmentSolicitorUserPageDtoApiResponse>({
    url: `/admin/investment-solicitor/users`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetAdminInvestmentSolicitorUsersQueryKey = (
  params?: GetAdminInvestmentSolicitorUsersParams,
) => {
  return [
    `/admin/investment-solicitor/users`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetAdminInvestmentSolicitorUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsers>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAdminInvestmentSolicitorUsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsers>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAdminInvestmentSolicitorUsersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsers>>
  > = ({ signal }) => getAdminInvestmentSolicitorUsers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminInvestmentSolicitorUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsers>>
>;
export type GetAdminInvestmentSolicitorUsersQueryError = ErrorType<unknown>;

/**
 * @summary 상담 고객 조회
 */
export const useGetAdminInvestmentSolicitorUsers = <
  TData = Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsers>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAdminInvestmentSolicitorUsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsers>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAdminInvestmentSolicitorUsersQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 상담 이력 리스트 검색
 */
export const getAdminInvestmentSolicitorConsultationsSearch = (
  params?: GetAdminInvestmentSolicitorConsultationsSearchParams,
  signal?: AbortSignal,
) => {
  return customAxios<InvestmentSolicitorConsultationPageDtoApiResponse>({
    url: `/admin/investment-solicitor/consultations/search`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetAdminInvestmentSolicitorConsultationsSearchQueryKey = (
  params?: GetAdminInvestmentSolicitorConsultationsSearchParams,
) => {
  return [
    `/admin/investment-solicitor/consultations/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetAdminInvestmentSolicitorConsultationsSearchQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getAdminInvestmentSolicitorConsultationsSearch>
  >,
  TError = ErrorType<unknown>,
>(
  params?: GetAdminInvestmentSolicitorConsultationsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getAdminInvestmentSolicitorConsultationsSearch>
        >,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAdminInvestmentSolicitorConsultationsSearchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminInvestmentSolicitorConsultationsSearch>>
  > = ({ signal }) =>
    getAdminInvestmentSolicitorConsultationsSearch(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminInvestmentSolicitorConsultationsSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminInvestmentSolicitorConsultationsSearchQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getAdminInvestmentSolicitorConsultationsSearch>>
  >;
export type GetAdminInvestmentSolicitorConsultationsSearchQueryError =
  ErrorType<unknown>;

/**
 * @summary 상담 이력 리스트 검색
 */
export const useGetAdminInvestmentSolicitorConsultationsSearch = <
  TData = Awaited<
    ReturnType<typeof getAdminInvestmentSolicitorConsultationsSearch>
  >,
  TError = ErrorType<unknown>,
>(
  params?: GetAdminInvestmentSolicitorConsultationsSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getAdminInvestmentSolicitorConsultationsSearch>
        >,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAdminInvestmentSolicitorConsultationsSearchQueryOptions(
      params,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 상담 이력 중 프리즘 기고객 및 전환 고객 조회
 * @summary 상담 고객 조회 검색
 */
export const getAdminInvestmentSolicitorUsersSearch = (
  params?: GetAdminInvestmentSolicitorUsersSearchParams,
  signal?: AbortSignal,
) => {
  return customAxios<InvestmentSolicitorUserPageDtoApiResponse>({
    url: `/admin/investment-solicitor/users/search`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetAdminInvestmentSolicitorUsersSearchQueryKey = (
  params?: GetAdminInvestmentSolicitorUsersSearchParams,
) => {
  return [
    `/admin/investment-solicitor/users/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetAdminInvestmentSolicitorUsersSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsersSearch>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAdminInvestmentSolicitorUsersSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsersSearch>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAdminInvestmentSolicitorUsersSearchQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsersSearch>>
  > = ({ signal }) => getAdminInvestmentSolicitorUsersSearch(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsersSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminInvestmentSolicitorUsersSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsersSearch>>
>;
export type GetAdminInvestmentSolicitorUsersSearchQueryError =
  ErrorType<unknown>;

/**
 * @summary 상담 고객 조회 검색
 */
export const useGetAdminInvestmentSolicitorUsersSearch = <
  TData = Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsersSearch>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAdminInvestmentSolicitorUsersSearchParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAdminInvestmentSolicitorUsersSearch>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAdminInvestmentSolicitorUsersSearchQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * https://www.notion.so/dataknows/f92dc795d05c4e86857777be4db390dc#f590426e4a0547b09df49389b6183c77
 * @summary 자문 계약 정보 조회
 */
export const postAdminInvestmentSolicitorContractSummaryPdf = (
  customerBaseReqDto: CustomerBaseReqDto,
) => {
  return customAxios<ContractSummaryResDtoListApiResponse>({
    url: `/admin/investment-solicitor/contract-summary-pdf`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: customerBaseReqDto,
  });
};

export const getPostAdminInvestmentSolicitorContractSummaryPdfMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postAdminInvestmentSolicitorContractSummaryPdf>
      >,
      TError,
      { data: CustomerBaseReqDto },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorContractSummaryPdf>>,
    TError,
    { data: CustomerBaseReqDto },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postAdminInvestmentSolicitorContractSummaryPdf>
      >,
      { data: CustomerBaseReqDto }
    > = (props) => {
      const { data } = props ?? {};

      return postAdminInvestmentSolicitorContractSummaryPdf(data);
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostAdminInvestmentSolicitorContractSummaryPdfMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorContractSummaryPdf>>
  >;
export type PostAdminInvestmentSolicitorContractSummaryPdfMutationBody =
  CustomerBaseReqDto;
export type PostAdminInvestmentSolicitorContractSummaryPdfMutationError =
  ErrorType<unknown>;

/**
 * @summary 자문 계약 정보 조회
 */
export const usePostAdminInvestmentSolicitorContractSummaryPdf = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorContractSummaryPdf>>,
    TError,
    { data: CustomerBaseReqDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorContractSummaryPdf>>,
  TError,
  { data: CustomerBaseReqDto },
  TContext
> => {
  const mutationOptions =
    getPostAdminInvestmentSolicitorContractSummaryPdfMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 계약 변경 정보 조회
 */
export const postAdminInvestmentSolicitorContractHistory = (
  customerInfoReqDto: CustomerInfoReqDto,
) => {
  return customAxios<ContractHistoryResDtoApiResponse>({
    url: `/admin/investment-solicitor/contract-history`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: customerInfoReqDto,
  });
};

export const getPostAdminInvestmentSolicitorContractHistoryMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorContractHistory>>,
    TError,
    { data: CustomerInfoReqDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorContractHistory>>,
  TError,
  { data: CustomerInfoReqDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorContractHistory>>,
    { data: CustomerInfoReqDto }
  > = (props) => {
    const { data } = props ?? {};

    return postAdminInvestmentSolicitorContractHistory(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminInvestmentSolicitorContractHistoryMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorContractHistory>>
  >;
export type PostAdminInvestmentSolicitorContractHistoryMutationBody =
  CustomerInfoReqDto;
export type PostAdminInvestmentSolicitorContractHistoryMutationError =
  ErrorType<unknown>;

/**
 * @summary 계약 변경 정보 조회
 */
export const usePostAdminInvestmentSolicitorContractHistory = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorContractHistory>>,
    TError,
    { data: CustomerInfoReqDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorContractHistory>>,
  TError,
  { data: CustomerInfoReqDto },
  TContext
> => {
  const mutationOptions =
    getPostAdminInvestmentSolicitorContractHistoryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 입출금 정보 조회 (조건부 신한 조회) (Local Test 터널링 필요)
 */
export const postAdminInvestmentSolicitorAccountTransactions = (
  customerInfoReqDto: CustomerInfoReqDto,
  params?: PostAdminInvestmentSolicitorAccountTransactionsParams,
) => {
  return customAxios<AccountTransactionResDtoApiResponse>({
    url: `/admin/investment-solicitor/account-transactions`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: customerInfoReqDto,
    params,
  });
};

export const getPostAdminInvestmentSolicitorAccountTransactionsMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postAdminInvestmentSolicitorAccountTransactions>
      >,
      TError,
      {
        data: CustomerInfoReqDto;
        params?: PostAdminInvestmentSolicitorAccountTransactionsParams;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorAccountTransactions>>,
    TError,
    {
      data: CustomerInfoReqDto;
      params?: PostAdminInvestmentSolicitorAccountTransactionsParams;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postAdminInvestmentSolicitorAccountTransactions>
      >,
      {
        data: CustomerInfoReqDto;
        params?: PostAdminInvestmentSolicitorAccountTransactionsParams;
      }
    > = (props) => {
      const { data, params } = props ?? {};

      return postAdminInvestmentSolicitorAccountTransactions(data, params);
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostAdminInvestmentSolicitorAccountTransactionsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorAccountTransactions>>
  >;
export type PostAdminInvestmentSolicitorAccountTransactionsMutationBody =
  CustomerInfoReqDto;
export type PostAdminInvestmentSolicitorAccountTransactionsMutationError =
  ErrorType<unknown>;

/**
 * @summary 입출금 정보 조회 (조건부 신한 조회) (Local Test 터널링 필요)
 */
export const usePostAdminInvestmentSolicitorAccountTransactions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorAccountTransactions>>,
    TError,
    {
      data: CustomerInfoReqDto;
      params?: PostAdminInvestmentSolicitorAccountTransactionsParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorAccountTransactions>>,
  TError,
  {
    data: CustomerInfoReqDto;
    params?: PostAdminInvestmentSolicitorAccountTransactionsParams;
  },
  TContext
> => {
  const mutationOptions =
    getPostAdminInvestmentSolicitorAccountTransactionsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 운용 정보 조회
 */
export const postAdminInvestmentSolicitorPortfolioDetail = (
  customerInfoReqDto: CustomerInfoReqDto,
) => {
  return customAxios<PortfolioDetailDtoApiResponse>({
    url: `/admin/investment-solicitor/portfolio-detail`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: customerInfoReqDto,
  });
};

export const getPostAdminInvestmentSolicitorPortfolioDetailMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorPortfolioDetail>>,
    TError,
    { data: CustomerInfoReqDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorPortfolioDetail>>,
  TError,
  { data: CustomerInfoReqDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorPortfolioDetail>>,
    { data: CustomerInfoReqDto }
  > = (props) => {
    const { data } = props ?? {};

    return postAdminInvestmentSolicitorPortfolioDetail(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminInvestmentSolicitorPortfolioDetailMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorPortfolioDetail>>
  >;
export type PostAdminInvestmentSolicitorPortfolioDetailMutationBody =
  CustomerInfoReqDto;
export type PostAdminInvestmentSolicitorPortfolioDetailMutationError =
  ErrorType<unknown>;

/**
 * @summary 운용 정보 조회
 */
export const usePostAdminInvestmentSolicitorPortfolioDetail = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorPortfolioDetail>>,
    TError,
    { data: CustomerInfoReqDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorPortfolioDetail>>,
  TError,
  { data: CustomerInfoReqDto },
  TContext
> => {
  const mutationOptions =
    getPostAdminInvestmentSolicitorPortfolioDetailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * https://www.notion.so/dataknows/admin-c65b30fbba3f42ae88e43a5cd3944513
 * @summary 이전 이력 조회 (신한포함) (Local Test 터널링 필요)
 */
export const postAdminInvestmentSolicitorAccountTransferProceedingWithShinhan =
  (customerInfoReqDto: CustomerInfoReqDto) => {
    return customAxios<AccountTransferProceedingResDtoApiResponse>({
      url: `/admin/investment-solicitor/account-transfer-proceeding/with-shinhan`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: customerInfoReqDto,
    });
  };

export const getPostAdminInvestmentSolicitorAccountTransferProceedingWithShinhanMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postAdminInvestmentSolicitorAccountTransferProceedingWithShinhan
        >
      >,
      TError,
      { data: CustomerInfoReqDto },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postAdminInvestmentSolicitorAccountTransferProceedingWithShinhan
      >
    >,
    TError,
    { data: CustomerInfoReqDto },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postAdminInvestmentSolicitorAccountTransferProceedingWithShinhan
        >
      >,
      { data: CustomerInfoReqDto }
    > = (props) => {
      const { data } = props ?? {};

      return postAdminInvestmentSolicitorAccountTransferProceedingWithShinhan(
        data,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostAdminInvestmentSolicitorAccountTransferProceedingWithShinhanMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof postAdminInvestmentSolicitorAccountTransferProceedingWithShinhan
      >
    >
  >;
export type PostAdminInvestmentSolicitorAccountTransferProceedingWithShinhanMutationBody =
  CustomerInfoReqDto;
export type PostAdminInvestmentSolicitorAccountTransferProceedingWithShinhanMutationError =
  ErrorType<unknown>;

/**
 * @summary 이전 이력 조회 (신한포함) (Local Test 터널링 필요)
 */
export const usePostAdminInvestmentSolicitorAccountTransferProceedingWithShinhan =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postAdminInvestmentSolicitorAccountTransferProceedingWithShinhan
        >
      >,
      TError,
      { data: CustomerInfoReqDto },
      TContext
    >;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof postAdminInvestmentSolicitorAccountTransferProceedingWithShinhan
      >
    >,
    TError,
    { data: CustomerInfoReqDto },
    TContext
  > => {
    const mutationOptions =
      getPostAdminInvestmentSolicitorAccountTransferProceedingWithShinhanMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };
/**
 * @summary 코칭 리스트 조회
 */
export const postAdminInvestmentSolicitorCoachings = (
  customerInfoReqDto: CustomerInfoReqDto,
  params?: PostAdminInvestmentSolicitorCoachingsParams,
) => {
  return customAxios<CustomerCoachingListDtoApiResponse>({
    url: `/admin/investment-solicitor/coachings`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: customerInfoReqDto,
    params,
  });
};

export const getPostAdminInvestmentSolicitorCoachingsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorCoachings>>,
    TError,
    {
      data: CustomerInfoReqDto;
      params?: PostAdminInvestmentSolicitorCoachingsParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorCoachings>>,
  TError,
  {
    data: CustomerInfoReqDto;
    params?: PostAdminInvestmentSolicitorCoachingsParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorCoachings>>,
    {
      data: CustomerInfoReqDto;
      params?: PostAdminInvestmentSolicitorCoachingsParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return postAdminInvestmentSolicitorCoachings(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminInvestmentSolicitorCoachingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorCoachings>>
>;
export type PostAdminInvestmentSolicitorCoachingsMutationBody =
  CustomerInfoReqDto;
export type PostAdminInvestmentSolicitorCoachingsMutationError =
  ErrorType<unknown>;

/**
 * @summary 코칭 리스트 조회
 */
export const usePostAdminInvestmentSolicitorCoachings = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorCoachings>>,
    TError,
    {
      data: CustomerInfoReqDto;
      params?: PostAdminInvestmentSolicitorCoachingsParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorCoachings>>,
  TError,
  {
    data: CustomerInfoReqDto;
    params?: PostAdminInvestmentSolicitorCoachingsParams;
  },
  TContext
> => {
  const mutationOptions =
    getPostAdminInvestmentSolicitorCoachingsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 자문 메뉴의 리스트 조회
 * @summary 자문 내역 조회
 */
export const postAdminInvestmentSolicitorContractDetailWithAccount = (
  customerInfoReqDto: CustomerInfoReqDto,
) => {
  return customAxios<ContractDetailWithAccountResDtoApiResponse>({
    url: `/admin/investment-solicitor/contract-detail/with-account`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: customerInfoReqDto,
  });
};

export const getPostAdminInvestmentSolicitorContractDetailWithAccountMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postAdminInvestmentSolicitorContractDetailWithAccount>
      >,
      TError,
      { data: CustomerInfoReqDto },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postAdminInvestmentSolicitorContractDetailWithAccount>
    >,
    TError,
    { data: CustomerInfoReqDto },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postAdminInvestmentSolicitorContractDetailWithAccount>
      >,
      { data: CustomerInfoReqDto }
    > = (props) => {
      const { data } = props ?? {};

      return postAdminInvestmentSolicitorContractDetailWithAccount(data);
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostAdminInvestmentSolicitorContractDetailWithAccountMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postAdminInvestmentSolicitorContractDetailWithAccount>
    >
  >;
export type PostAdminInvestmentSolicitorContractDetailWithAccountMutationBody =
  CustomerInfoReqDto;
export type PostAdminInvestmentSolicitorContractDetailWithAccountMutationError =
  ErrorType<unknown>;

/**
 * @summary 자문 내역 조회
 */
export const usePostAdminInvestmentSolicitorContractDetailWithAccount = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postAdminInvestmentSolicitorContractDetailWithAccount>
    >,
    TError,
    { data: CustomerInfoReqDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof postAdminInvestmentSolicitorContractDetailWithAccount>
  >,
  TError,
  { data: CustomerInfoReqDto },
  TContext
> => {
  const mutationOptions =
    getPostAdminInvestmentSolicitorContractDetailWithAccountMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
/**
 * https://www.notion.so/dataknows/f92dc795d05c4e86857777be4db390dc#80f2ca84f86e4dc2aaa7a2317a0cd0e3
 * @summary 금융 -> 투자 성향 정보 조회
 */
export const postAdminInvestmentSolicitorPropensity = (
  customerBaseReqDto: CustomerBaseReqDto,
) => {
  return customAxios<InvestmentPropensityResDtoListApiResponse>({
    url: `/admin/investment-solicitor/propensity`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: customerBaseReqDto,
  });
};

export const getPostAdminInvestmentSolicitorPropensityMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorPropensity>>,
    TError,
    { data: CustomerBaseReqDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorPropensity>>,
  TError,
  { data: CustomerBaseReqDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorPropensity>>,
    { data: CustomerBaseReqDto }
  > = (props) => {
    const { data } = props ?? {};

    return postAdminInvestmentSolicitorPropensity(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminInvestmentSolicitorPropensityMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorPropensity>>
>;
export type PostAdminInvestmentSolicitorPropensityMutationBody =
  CustomerBaseReqDto;
export type PostAdminInvestmentSolicitorPropensityMutationError =
  ErrorType<unknown>;

/**
 * @summary 금융 -> 투자 성향 정보 조회
 */
export const usePostAdminInvestmentSolicitorPropensity = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorPropensity>>,
    TError,
    { data: CustomerBaseReqDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorPropensity>>,
  TError,
  { data: CustomerBaseReqDto },
  TContext
> => {
  const mutationOptions =
    getPostAdminInvestmentSolicitorPropensityMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 금융 -> 보유 계좌 정보
 */
export const postAdminInvestmentSolicitorAccountList = (
  customerInfoReqDto: CustomerInfoReqDto,
) => {
  return customAxios<AccountListResDtoApiResponse>({
    url: `/admin/investment-solicitor/account-list`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: customerInfoReqDto,
  });
};

export const getPostAdminInvestmentSolicitorAccountListMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorAccountList>>,
    TError,
    { data: CustomerInfoReqDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorAccountList>>,
  TError,
  { data: CustomerInfoReqDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorAccountList>>,
    { data: CustomerInfoReqDto }
  > = (props) => {
    const { data } = props ?? {};

    return postAdminInvestmentSolicitorAccountList(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminInvestmentSolicitorAccountListMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorAccountList>>
>;
export type PostAdminInvestmentSolicitorAccountListMutationBody =
  CustomerInfoReqDto;
export type PostAdminInvestmentSolicitorAccountListMutationError =
  ErrorType<unknown>;

/**
 * @summary 금융 -> 보유 계좌 정보
 */
export const usePostAdminInvestmentSolicitorAccountList = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorAccountList>>,
    TError,
    { data: CustomerInfoReqDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorAccountList>>,
  TError,
  { data: CustomerInfoReqDto },
  TContext
> => {
  const mutationOptions =
    getPostAdminInvestmentSolicitorAccountListMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * https://www.notion.so/dataknows/f92dc795d05c4e86857777be4db390dc#8439b6e2ff894d1cb0206cd2f900e18b
 * @summary 금융 -> 자동 이체 정보
 */
export const postAdminInvestmentSolicitorFeeAccountSummary = (
  customerBaseReqDto: CustomerBaseReqDto,
) => {
  return customAxios<FeeAccountSummaryResDtoListApiResponse>({
    url: `/admin/investment-solicitor/fee-account-summary`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: customerBaseReqDto,
  });
};

export const getPostAdminInvestmentSolicitorFeeAccountSummaryMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorFeeAccountSummary>>,
    TError,
    { data: CustomerBaseReqDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorFeeAccountSummary>>,
  TError,
  { data: CustomerBaseReqDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorFeeAccountSummary>>,
    { data: CustomerBaseReqDto }
  > = (props) => {
    const { data } = props ?? {};

    return postAdminInvestmentSolicitorFeeAccountSummary(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminInvestmentSolicitorFeeAccountSummaryMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorFeeAccountSummary>>
  >;
export type PostAdminInvestmentSolicitorFeeAccountSummaryMutationBody =
  CustomerBaseReqDto;
export type PostAdminInvestmentSolicitorFeeAccountSummaryMutationError =
  ErrorType<unknown>;

/**
 * @summary 금융 -> 자동 이체 정보
 */
export const usePostAdminInvestmentSolicitorFeeAccountSummary = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminInvestmentSolicitorFeeAccountSummary>>,
    TError,
    { data: CustomerBaseReqDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminInvestmentSolicitorFeeAccountSummary>>,
  TError,
  { data: CustomerBaseReqDto },
  TContext
> => {
  const mutationOptions =
    getPostAdminInvestmentSolicitorFeeAccountSummaryMutationOptions(options);

  return useMutation(mutationOptions);
};
