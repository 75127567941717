import styled from "@/theme";

type TagProps = {
  color: "positive" | "sub01" | "sub03" | "sub05";
  text: string;
};

const Tag = ({ color, text }: TagProps) => {
  return <Container color={color}>{text}</Container>;
};

const Container = styled.div<{
  color: TagProps["color"];
}>`
  width: fit-content;

  padding: 4.5px 5.5px;

  border-radius: 8px;

  background-color: ${(p) =>
    p.color === "positive"
      ? p.theme.colors.positive100
      : p.color === "sub03"
        ? p.theme.colors.sub03_100
        : p.color === "sub05"
          ? p.theme.colors.sub05_100
          : p.theme.colors.sub01_100};

  color: ${(p) =>
    p.color === "positive"
      ? p.theme.colors.positive500
      : p.color === "sub03"
        ? p.theme.colors.sub03_500
        : p.color === "sub05"
          ? p.theme.colors.sub05_500
          : p.theme.colors.sub01_500};
  text-align: center;

  /* Paragraph 03/Semibold */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export default Tag;
