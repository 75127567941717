import { logout, useAppDispatch } from "@/store";
import tokens from "@/tokens";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { usePostAdminUserSignOut } from "../remoteStore";

const useSignOut = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mutateAsync: postSignOut } = usePostAdminUserSignOut();

  const signOut = async () => {
    try {
      await postSignOut();
      await tokens.clearTokens();
      dispatch(logout());
      navigate("/");
      toast.success("로그아웃 되었습니다.");
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("로그아웃 중 오류가 발생했습니다.");
    }
  };

  return { signOut };
};

export default useSignOut;
