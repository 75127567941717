import React from "react";
import { Modal } from "antd";
import styled from "@/theme";
import Icon from "@ant-design/icons";
import { useGetAdminReservationConsultingReservationIdInfo } from "@/api";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber";
import Tag from "@/components/Tag";
import Space from "@/components/Space";
import formatReservationDate from "@/utils/formatReservationDate";

const SURVEY_DATA = {
  appExperience: [
    "프리즘으로 자문계약 투자중이에요",
    "앱은 설치했지만 투자는 하고 있지 않아요",
    "앱 설치 및 사용 경험이 없어요",
  ],
  mainConcern: [
    "퇴직금 불리기",
    "은퇴 후 자산관리",
    "금융소득종합과세 걱정",
    "법인 투자 희망",
    "자녀 증여/상속 투자",
    "노후/연금 준비",
    "단기 자금 준비(3년 이하)",
    "중장기 자금 준비(5년 이상)",
    "보유 중인 보험 점검",
    "보유 중인 연금 점검",
    "프리즘에서 내 투자 관리 희망",
  ],
  incomeSource: ["급여 소득", "사업 소득", "그 외(연금/배당/임대 등)"],
  financialIncomeTax: ["있음", "없음", "잘 모르겠어요"],
} as const;

// Types
interface ResModalProps {
  id?: number;
  showModal: boolean;
  onClose: () => void;
}

interface TextBoxProps {
  noData?: boolean;
}

// Component
const ResModal: React.FC<ResModalProps> = ({ id, showModal, onClose }) => {
  const { data } = useGetAdminReservationConsultingReservationIdInfo(id ?? 0);
  const reservationData = data?.result;

  const surveyAnswers = {
    appExperience: reservationData?.surveyAnswerList[0].numAnswer!,
    mainConcern: reservationData?.surveyAnswerList[1].numArrAnswer,
    mainConcernDetail: reservationData?.surveyAnswerList[2].strAnswer,
    annualIncome: reservationData?.surveyAnswerList[3].strAnswer,
    incomeSource: reservationData?.surveyAnswerList[4].numArrAnswer,
    financialIncomeTax: reservationData?.surveyAnswerList[5].numAnswer!,
    monthlyInvestment: reservationData?.surveyAnswerList[6].strAnswer,
    assets: {
      pensionISAIRP: reservationData?.surveyAnswerList[7].strAnswer,
      stock: reservationData?.surveyAnswerList[8].strAnswer,
      bond: reservationData?.surveyAnswerList[9].strAnswer,
      cash: reservationData?.surveyAnswerList[10].strAnswer,
      insurance: reservationData?.surveyAnswerList[11].strAnswer,
    },
  };

  const formatAmount = (amount: string | null) =>
    amount ? `${Number(amount).toLocaleString()} 만원` : "없음";

  const CUSTOMER_INFO = [
    {
      title: "금소세 납부이력",
      value: SURVEY_DATA.financialIncomeTax[surveyAnswers.financialIncomeTax],
    },
    {
      title: "연소득",
      value: formatAmount(surveyAnswers.annualIncome ?? "0"),
    },
    {
      title: "소득 출처",
      values: surveyAnswers.incomeSource?.map(
        (item) => SURVEY_DATA.incomeSource[item]
      ),
    },
    {
      title: "월 투자가능액",
      value: formatAmount(surveyAnswers.monthlyInvestment ?? "0"),
    },
  ];

  const ASSET_INFO = [
    {
      title: "연금저축/ISA/IRP 자산",
      value: surveyAnswers.assets.pensionISAIRP,
    },
    { title: "주식형 자산", value: surveyAnswers.assets.stock },
    { title: "채권형 자산", value: surveyAnswers.assets.bond },
    { title: "현금성 자산", value: surveyAnswers.assets.cash },
    { title: "보험 자산", value: surveyAnswers.assets.insurance },
  ].map(({ title, value }) => ({
    title,
    value: formatAmount(value ?? "0"),
    noData: value === null,
  }));

  return (
    <StyledModal
      centered
      open={showModal}
      onCancel={onClose}
      footer={null}
      width={1100}
    >
      <Section>
        <Row>
          <Name>{reservationData?.crmCustomerName}</Name>
          <DividerLight />
          <Age>{reservationData?.crmCustomerAge}세</Age>
          <DividerLight />
          <Icon name="phone" width={16} height={16} />
          <Phone>
            {formatPhoneNumber(reservationData?.crmCustomerPhoneNumber)}
          </Phone>
        </Row>
        <Space h={12} />
        <Row>
          <Tag
            color={
              reservationData?.consultingTypeCode === "대면상담"
                ? "positive"
                : reservationData?.consultingTypeCode === "유선상담"
                  ? "sub03"
                  : "sub05"
            }
            text={reservationData?.consultingTypeCode ?? ""}
          />
          <Space w={10} />
          <Tag color="sub01" text="예약 확정" />
          <Space w={10} />
          <DateText>
            {formatReservationDate(reservationData?.consultingStartDtm)}
          </DateText>
        </Row>
        <Space h={12} />
        <Row>
          <Address>{reservationData?.consultingAddress}</Address>
        </Row>
      </Section>

      <Divider />

      <Container>
        <InnerContainer>
          <Section>
            <Title>프리즘 앱 사용 경험</Title>
            <TextBoxContainer>
              <TextBox>
                {SURVEY_DATA.appExperience[surveyAnswers.appExperience]}
              </TextBox>
            </TextBoxContainer>
          </Section>

          <Section>
            <Title>주요 고민</Title>
            <TextBoxContainer>
              {surveyAnswers.mainConcern?.map((item) => (
                <TextBox key={item}>{SURVEY_DATA.mainConcern[item]}</TextBox>
              ))}
            </TextBoxContainer>
          </Section>

          <Section>
            <Title>주요 고민 상세</Title>
            <TextBoxContainer>
              <TextBox>{surveyAnswers.mainConcernDetail}</TextBox>
            </TextBoxContainer>
          </Section>

          <Divider />

          {CUSTOMER_INFO.map(({ title, value, values }) => (
            <Row2 key={title}>
              <Title>{title}</Title>
              {values ? (
                <TextBoxContainer>
                  {values.map((v) => (
                    <TextBox key={v}>{v}</TextBox>
                  ))}
                </TextBoxContainer>
              ) : (
                <TextBox>{value}</TextBox>
              )}
            </Row2>
          ))}

          <Divider />

          {ASSET_INFO.map(({ title, value, noData }) => (
            <Row2 key={title}>
              <Title>{title}</Title>
              <TextBox noData={noData}>{noData ? "없음" : value}</TextBox>
            </Row2>
          ))}
        </InnerContainer>
        <MemoContainer>
          <Title>상담 전</Title>
          <TextArea placeholder="내용을 입력해주세요." />
          <Space h={30} />
          <Title>상담 후</Title>
          <TextArea placeholder="내용을 입력해주세요." />
        </MemoContainer>
      </Container>
    </StyledModal>
  );
};

const Address = styled.div`
  color: var(--Shades-Black, #000);

  /* Paragraph 01/Semibold */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: ${(p) => p.theme.colors.gray500};
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 8px;
`;

const TextBox = styled.div<TextBoxProps>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: ${(p) => (p.noData ? p.theme.colors.gray300 : p.theme.colors.gray700)};
  background-color: ${(p) =>
    p.noData ? p.theme.colors.gray100 : p.theme.colors.gray50};
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${(p) => p.theme.colors.gray200};
  margin: 16px 0;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 14px 16px;
  border: 1px solid ${(p) => p.theme.colors.gray100};
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.white};
  resize: none;

  color: var(--Gray-Gray-400, #7a8190);
  /* Paragraph 02/Medium */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.667% */

  &::placeholder {
    color: ${(p) => p.theme.colors.gray300};
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MemoContainer = styled(InnerContainer)``;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Row2 = styled(Row)`
  justify-content: space-between;
  margin-top: 20px;
`;

const Text = styled.div`
  font-family: Pretendard;
  font-weight: 600;
`;

const Name = styled(Text)`
  font-size: 20px;
  line-height: 32px;
  color: ${(p) => p.theme.colors.black};
`;

const Age = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.black};
`;

const Phone = styled(Age)`
  margin-left: 6px;
`;

const DateText = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.gray500};
`;

const DividerLight = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${(p) => p.theme.colors.gray400};
  margin: 0 10px;
`;

const TextBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default ResModal;
