import { Button, Table } from "antd";
import {
  ContractDetailWithAccountDto,
  getAdminInvestmentSolicitorUsersSearch,
  InvestmentSolicitorUserResDto,
  postAdminInvestmentSolicitorContractDetailWithAccount,
  useGetAdminInvestmentSolicitorUsers,
} from "@/api";
import useCustomersColumns from "./useCustomersColumns";
import { useImmer } from "use-immer";
import useAsyncEffect from "@/utils/useAsyncEffect";
import useContractsColumns from "./useContractsColumns";
import { CloseOutlined } from "@ant-design/icons";
import SearchComponent from "../SearchComponent";
import { useEffect } from "react";
import styled from "@/theme";

const Customers = () => {
  const [state, setState] = useImmer({
    page: 1,
    size: 15,
    userId: undefined as string | undefined,
    data: [] as InvestmentSolicitorUserResDto[] | undefined,
    totalCount: 0 as number | undefined,
    contractDetailWithAccountList: undefined as
      | ContractDetailWithAccountDto[]
      | undefined,
  });

  const { data, refetch } = useGetAdminInvestmentSolicitorUsers({
    PageNum: state.page,
    PageSize: state.size,
  });

  useEffect(() => {
    if (data?.result?.items) {
      setState((dr) => {
        dr.data = data.result?.items;
        dr.totalCount = data.result?.totalCount;
      });
    }
  }, [data?.result?.items]);

  const onClickDetail = (userId: string) => {
    setState((dr) => {
      dr.userId = userId;
    });
  };

  const { columns, dataSource } = useCustomersColumns({
    data: state.data,
    onClickDetail,
  });

  const { columns: contractsColumns, dataSource: contractsDataSource } =
    useContractsColumns({
      data: state.contractDetailWithAccountList,
    });

  useAsyncEffect(async () => {
    if (!state.userId) {
      return;
    }
    const { result } =
      await postAdminInvestmentSolicitorContractDetailWithAccount({
        reason: "기타",
        userId: state.userId,
      });

    setState((dr) => {
      dr.contractDetailWithAccountList = result?.contractDetailWithAccountList;
    });
  }, [state.userId]);

  const onChangePage = (page: number, size: number) => {
    setState((dr) => {
      dr.page = page;
      dr.size = size;
    });
  };

  const handleCloseTable = () => {
    setState((dr) => {
      dr.userId = undefined;
    });
  };

  const handleSearch = async (customerName: string) => {
    setState((dr) => {
      dr.page = 1;
    });
    try {
      const { result } = await getAdminInvestmentSolicitorUsersSearch({
        q: customerName,
        PageNum: 1,
        PageSize: 15,
      });

      console.log("result", result);

      if (result?.items) {
        setState((dr) => {
          dr.data = result.items;
          dr.totalCount = result.totalCount;
        });
      } else {
        setState((dr) => {
          dr.data = [];
          dr.totalCount = 0;
        });
      }
    } catch (error) {
      console.error("데이터 조회에 실패했습니다");
      console.error(error);
    }
  };

  const onRefresh = async () => {
    await refetch();
    setState((dr) => {
      dr.page = 1;
      dr.userId = undefined;
      dr.contractDetailWithAccountList = undefined;
    });
  };

  return (
    <Container>
      <TableWrapper>
        <SearchComponent onSearch={handleSearch} onRefresh={onRefresh} />
        <Table
          columns={columns}
          dataSource={dataSource}
          scroll={{
            y: state.userId ? "calc(50vh - 200px)" : "calc(100vh - 300px)",
          }}
          pagination={{
            pageSize: state.size,
            total: state.totalCount,
            current: state.page,
            onChange: onChangePage,
            pageSizeOptions: ["15", "30", "50", "100"],
          }}
        />
      </TableWrapper>
      {!!state.userId && (
        <div style={{ position: "relative" }}>
          <Button
            type="primary"
            icon={<CloseOutlined />}
            onClick={handleCloseTable}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            닫기
          </Button>
          <Table
            columns={contractsColumns}
            dataSource={contractsDataSource}
            scroll={{ y: "calc(50vh - 200px)" }}
            style={{ marginTop: 46 }}
            pagination={false}
          />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const TableWrapper = styled.div``;

export default Customers;
