import {
  gray,
  negative,
  positive,
  primary,
  shades,
  sub01,
  sub02,
  sub03,
  sub04,
  sub05,
  tendencyNeutral,
  neutral,
} from "./baseColors";

import assetCategoryColors from "./assetCategoryColors";
import buttonColors from "./buttonColors";
import tagColors from "./tagColors";
import tabsColors from "./tabsColors";

const colors = {
  ...gray,
  ...primary,
  ...sub01,
  ...sub02,
  ...sub03,
  ...sub04,
  ...sub05,
  ...positive,
  ...negative,
  ...tendencyNeutral,
  ...neutral,
  ...shades,

  ...buttonColors,
  ...tagColors,
  ...assetCategoryColors,
  ...tabsColors,

  background: shades.white,
  // FIXME: kangseongofdk
  // dimmedBackground 말고 다른 이름을 고려할 것
  dimmedBackground: gray.gray50,
  surface: shades.white,
  onBackground: shades.black,
  statusBar: "transparent",

  gnbBackground: shades.white,
  gnbBorder: gray.gray100,
  gnbTabIconActive: gray.gray600,
  gnbTabIconInactive: gray.gray300,
  colorActiveTabButtonLabel: "rgba(51, 58, 70, 1)",
  colorInactiveTabButtonLabel: "rgba(109, 119, 135, 1)",

  keypadBackground: "rgba(20, 42, 79, 1)",

  colorInactiveSecret: "rgba(231, 234, 237, 1)",
  colorActiveSecret: "rgba(20, 42, 79, 1)",

  loadingOverlayBackground: "rgba(255, 255, 255, 0.2)",

  keypadSurface: primary.primary400,
  keypadText: shades.white,

  inputSurface: shades.white,
  inputBorder: gray.gray100,
  inputPlaceholder: gray.gray300,
  inputText: shades.black,

  inputFocusSurface: gray.gray50,
  inputFocusBorder: gray.gray200,

  inputDisabledSurface: gray.gray50,
  inputDisabledBorder: gray.gray100,

  inputErrorSurface: negative.negative100,
  inputErrorBorder: negative.negative400,

  selectSurface: shades.white,
  selectBorder: gray.gray100,

  selectErrorSurface: negative.negative100,
  selectErrorBorder: negative.negative400,

  areaChartSeries0: sub01.sub01_400,
  areaChartSeries1: sub02.sub02_400,
  areaChartSeries2: sub03.sub03_400,
  areaChartSeries3: sub04.sub04_400,

  pieChartSeries0: sub01.sub01_400,
  pieChartSeries1: sub02.sub02_400,
  pieChartSeries2: sub03.sub03_400,
  pieChartSeries3: sub04.sub04_400,
  pieChartSeries4: sub05.sub05_400,
  pieChartSeries5: gray.gray300,

  lineChartSeries0: primary.primary200,
  lineChartSeries1: primary.primary300,
  lineChartSeries2: negative.negative400,

  mainLineChartSeriesAll: primary.primary400,
  mainLineChartSeriesPensionSavings: sub01.sub01_400,
  mainLineChartSeriesIrp: sub02.sub02_400,
  mainLineChartSeriesIsa: sub03.sub03_400,
  mainLineChartSeriesCma: sub04.sub04_400,

  selectableLineChartSeriesSafeMaxi: sub01.sub01_200,
  selectableLineChartSeriesSafe: sub02.sub02_200,
  selectableLineChartSeriesNeutral: sub03.sub03_200,
  selectableLineChartSeriesGrowth: sub04.sub04_200,

  selectableLineChartSeriesSafeMaxiMain: sub01.sub01_400,
  selectableLineChartSeriesSafeMain: sub02.sub02_400,
  selectableLineChartSeriesNeutralMain: sub03.sub03_400,
  selectableLineChartSeriesGrowthMain: sub04.sub04_400,

  horizontalBarItem주식형: sub01.sub01_400,
  horizontalBarItem채권형: sub02.sub02_400,
  horizontalBarItem혼합형: sub03.sub03_400,
  horizontalBarItem대체투자: sub04.sub04_400,
  horizontalBarItem현금성자산: sub05.sub05_400,
  horizontalBarItem현금: gray.gray200,

  horizontalBarItem연금저축: sub01.sub01_400,
  horizontalBarItemIrp: sub02.sub02_400,
  horizontalBarItemIsa: sub03.sub03_400,
  horizontalBarItemCma: sub04.sub04_400,

  columnChartSeries0: sub01.sub01_400,
  columnChartSeries1: sub02.sub02_400,
  columnChartSeries2: sub03.sub03_400,
  columnChartSeries3: sub04.sub04_400,
  columnChartSeries4: sub05.sub05_400,
  columnChartSeries5: gray.gray300,

  selectableColumnChartSeriesSafe: sub01.sub01_200,
  selectableColumnChartSeriesNeutral: sub02.sub02_200,
  selectableColumnChartSeriesGrowth: sub03.sub03_200,
  selectableColumnChartSeriesSafeMain: sub01.sub01_400,
  selectableColumnChartSeriesNeutralMain: sub02.sub02_400,
  selectableColumnChartSeriesGrowthMain: sub03.sub03_400,

  chartXAxisText: gray.gray300,
  chartXAxisLine: gray.gray200,
  chartXAxisTicks: gray.gray100,
  chartYAxisText: gray.gray300,
  chartYAxisLine: "transparent",
  chartYAxisTicks: "transparent",
  chartYAxisGrid: gray.gray50,

  tendencySurveyItem: gray.gray100,
  tendencySurveyItem0: sub01.sub01_400,
  tendencySurveyItem1: sub02.sub02_400,
  tendencySurveyItem2: sub03.sub03_400,
  tendencySurveyItem3: sub04.sub04_400,

  bannerGradientColor1: "rgba(246, 248, 255, 1)",
  bannerGradientColor2: "rgba(227, 235, 255, 1)",
  bannerGradientColor3: "rgba(255, 254, 246, 1)",
  bannerGradientColor4: "rgba(255, 252, 227, 1)",
  bannerGradientColor5: "rgba(252, 255, 255, 1)",
  bannerGradientColor6: "rgba(227, 247, 255, 1)",
  bannerGradientColor7: "rgba(231, 250, 236, 1)",

  buttonEventColor1: "rgba(10, 162, 113, 1)",
};

export default colors;
