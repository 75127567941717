import Modal, { ModalProps } from "@/components/Modal";
import styled from "@/theme";
import { useState } from "react";
import CheckboxTable from "./CheckboxTable";
import TextArea from "antd/es/input/TextArea";

type StatusModalProps = ModalProps & {
  onConfirm: () => void;
};

const FRISM_PRODUCT_TYPES = ["CMA", "연금저축", "IRP", "ISA"] as const;
const OTHER_PRODUCT_TYPES = ["개인 고객", "법인 고객"] as const;
export const STATUS_TYPES = ["신규", "이전"] as const;

export type StatusType = (typeof STATUS_TYPES)[number]; // "신규" | "이전"
type FrismProductType = (typeof FRISM_PRODUCT_TYPES)[number];
type OtherProductType = (typeof OTHER_PRODUCT_TYPES)[number];

export type CheckboxState<T extends string> = {
  [K in StatusType]: Record<T, boolean>;
};

const StatusModal = ({ showModal, onClose, onConfirm }: StatusModalProps) => {
  const [frismCheckboxState, setFrismCheckboxState] = useState<
    CheckboxState<FrismProductType>
  >(() => ({
    신규: Object.fromEntries(
      FRISM_PRODUCT_TYPES.map((type) => [type, true])
    ) as Record<FrismProductType, boolean>,
    이전: Object.fromEntries(
      FRISM_PRODUCT_TYPES.map((type) => [type, false])
    ) as Record<FrismProductType, boolean>,
  }));

  const [otherCheckboxState, setOtherCheckboxState] = useState<
    CheckboxState<OtherProductType>
  >(() => ({
    신규: Object.fromEntries(
      OTHER_PRODUCT_TYPES.map((type) => [type, true])
    ) as Record<OtherProductType, boolean>,
    이전: Object.fromEntries(
      OTHER_PRODUCT_TYPES.map((type) => [type, false])
    ) as Record<OtherProductType, boolean>,
  }));

  const handleCheckboxChange =
    <T extends string>(
      setState: React.Dispatch<React.SetStateAction<CheckboxState<T>>>
    ) =>
    (status: StatusType, product: T) => {
      setState((prev) => ({
        ...prev,
        [status]: {
          ...prev[status],
          [product]: !prev[status][product],
        },
      }));
    };

  return (
    <Modal showModal={showModal} onClose={onClose}>
      <Container>
        <Title>고객 상태 설정</Title>
        <Desc>고객의 상태가 확정되면, 상담 완료 처리됩니다.</Desc>

        <CheckboxTable
          title="프리즘 보유 현황"
          products={FRISM_PRODUCT_TYPES}
          checkboxState={frismCheckboxState}
          onCheckboxChange={handleCheckboxChange(setFrismCheckboxState)}
        />

        <CheckboxTable
          title="그 외 증권사"
          products={OTHER_PRODUCT_TYPES}
          checkboxState={otherCheckboxState}
          onCheckboxChange={handleCheckboxChange(setOtherCheckboxState)}
        />

        <TextAreaContainer>
          <TextAreaLabel>상담 내용</TextAreaLabel>
          <StyledTextArea
            placeholder="상담 내용을 입력해주세요."
            rows={4}
            maxLength={1000}
          />
        </TextAreaContainer>

        <ButtonContainer>
          <ConfirmButton
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            완료
          </ConfirmButton>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

const TextAreaContainer = styled.div`
  margin-top: 24px;
`;

const TextAreaLabel = styled.div`
  ${(p) => p.theme.text.p4Semibold};
  color: ${(p) => p.theme.colors.gray400};

  margin-bottom: 6px;
`;

const StyledTextArea = styled(TextArea)`
  border-radius: 12px;
  border: 1px solid var(--Gray-Gray-100, #eeeff3);

  ${(p) => p.theme.text.p3Medium};
  color: ${(p) => p.theme.colors.gray800};

  ::placeholder {
    color: ${(p) => p.theme.colors.gray300};
  }

  padding: 14px 16px;
`;

const Container = styled.div`
  width: 476px;
  padding: 24px 32px;
`;

const Title = styled.div`
  color: var(--Color-Dark-Dark, #23262f);
  text-align: center;

  /* Heading 01/Bold */
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
`;

const Desc = styled.div`
  color: var(--Gray-Gray-400, #7a8190);
  text-align: center;

  /* Paragraph 01/Medium */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */

  margin-top: 12px;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const ConfirmButton = styled.button`
  padding: 13px 84px;
  background-color: ${(p) => p.theme.colors.primary400};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  ${(p) => p.theme.text.p2Medium};

  &:hover {
    opacity: 0.9;
  }
`;

export default StatusModal;
