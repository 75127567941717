import { Table } from "antd";
import {
  getAdminInvestmentSolicitorConsultationsSearch,
  InvestmentSolicitorConsultationResDto,
  useGetAdminInvestmentSolicitorConsultations,
} from "@/api";
import useConsultationsColumns from "./useConsultationsColumns";
import { useImmer } from "use-immer";
import SearchComponent from "../SearchComponent";
import { useEffect } from "react";
import styled from "@/theme";

const Consultations = () => {
  const [state, setState] = useImmer({
    page: 1,
    size: 15,
    data: [] as InvestmentSolicitorConsultationResDto[] | undefined,
    totalCount: 0 as number | undefined,
  });

  const { data, refetch } = useGetAdminInvestmentSolicitorConsultations({
    PageNum: state.page,
    PageSize: state.size,
  });

  useEffect(() => {
    if (data?.result?.items) {
      setState((dr) => {
        dr.data = data.result?.items;
        dr.totalCount = data.result?.totalCount;
      });
    }
  }, [data?.result?.items]);

  const { columns, dataSource } = useConsultationsColumns({
    data: state.data,
  });

  const onChangePage = (page: number, size: number) => {
    setState((dr) => {
      dr.page = page;
      dr.size = size;
    });
  };

  const handleSearch = async (customerName: string) => {
    setState((dr) => {
      dr.page = 1;
    });

    try {
      const { result } = await getAdminInvestmentSolicitorConsultationsSearch({
        q: customerName,
        PageNum: 1,
        PageSize: 15,
      });

      console.log("result", result);

      if (result?.items) {
        setState((dr) => {
          dr.data = result.items;
          dr.totalCount = result.totalCount;
        });
      } else {
        setState((dr) => {
          dr.data = [];
          dr.totalCount = 0;
        });
      }
    } catch (error) {
      console.error("데이터 조회에 실패했습니다");
      console.error(error);
    }
  };

  const onRefresh = async () => {
    await refetch();
    setState((dr) => {
      dr.page = 1;
    });
  };

  return (
    <Container>
      <SearchComponent onSearch={handleSearch} onRefresh={onRefresh} />
      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={{ y: "calc(100vh - 300px)" }}
        pagination={{
          pageSize: state.size,
          total: state.totalCount,
          current: state.page,
          onChange: onChangePage,
          pageSizeOptions: ["15", "30", "50", "100"],
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 100px); // Adjust this value based on your layout
  overflow: auto;
`;

export default Consultations;
