// SessionStorage를 사용하는 토큰 관리 버전입니다:

import parseJwt from "./parseJwt";

const KEY_ACCESS_TOKEN = "accessToken";
const KEY_REFRESH_TOKEN = "refreshToken";

type SessionState = {
  accessToken: string | null;
  accessTokenExpired: number | null;
  username: string | null;
  refreshToken: string | null;
  isAdmin: boolean;
  isCustomerService: boolean;
  isInvestmentSolicitor: boolean;
  isInvestmentSolicitorAdmin: boolean;
  isMarketer: boolean;
};

const _state: SessionState = {
  accessToken: null,
  accessTokenExpired: null,
  username: null,
  refreshToken: null,
  isAdmin: false,
  isCustomerService: false,
  isInvestmentSolicitor: false,
  isInvestmentSolicitorAdmin: false,
  isMarketer: false,
};

const setAccessToken = (token: string | null) => {
  console.log("KEY_ACCESS_TOKEN, token", KEY_ACCESS_TOKEN, token);
  _state.accessToken = token;
  const parsedJwt = token === null ? null : parseJwt(token);
  _state.accessTokenExpired = parsedJwt === null ? null : parsedJwt.exp * 1000;

  if (token !== null) {
    sessionStorage.setItem(KEY_ACCESS_TOKEN, token);
  }
};

const setRefreshToken = (token: string | null) => {
  console.log("KEY_REFRESH_TOKEN, token", KEY_REFRESH_TOKEN, token);
  _state.refreshToken = token;

  if (token !== null) {
    sessionStorage.setItem(KEY_REFRESH_TOKEN, token);
  }
};

const setRole = (accessToken: string) => {
  const parsedAccessToken = !accessToken ? null : parseJwt(accessToken);
  _state.username =
    parsedAccessToken === null ? null : parsedAccessToken?.username;
  _state.isAdmin =
    parsedAccessToken === null
      ? false
      : parsedAccessToken["cognito:groups"].includes("Admin");
  _state.isCustomerService =
    parsedAccessToken === null
      ? false
      : parsedAccessToken["cognito:groups"].includes("CustomerService");
  _state.isInvestmentSolicitor =
    parsedAccessToken === null
      ? false
      : parsedAccessToken["cognito:groups"].includes("투자권유인");
  _state.isInvestmentSolicitorAdmin =
    parsedAccessToken === null
      ? false
      : parsedAccessToken["cognito:groups"].includes("투자권유인관리자");
  _state.isMarketer =
    parsedAccessToken === null
      ? false
      : parsedAccessToken["cognito:groups"].includes("Marketer");
};

const tokens = {
  init() {
    const accessToken = sessionStorage.getItem(KEY_ACCESS_TOKEN);
    const refreshToken = sessionStorage.getItem(KEY_REFRESH_TOKEN);

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  },

  setTokens(accessToken: string, refreshToken: string | null) {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setRole(accessToken);
  },

  setAccessToken,

  clearTokens() {
    try {
      const removeKeys = [KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN];

      removeKeys.forEach((key) => {
        if (sessionStorage.getItem(key)) {
          sessionStorage.removeItem(key);
        }
      });
    } catch (error) {
      console.log("error while clearTokens", error);
    } finally {
      _state.accessToken = null;
      _state.accessTokenExpired = null;
      _state.username = null;
      _state.refreshToken = null;
      _state.isAdmin = false;
      _state.isCustomerService = false;
      _state.isInvestmentSolicitor = false;
      _state.isInvestmentSolicitorAdmin = false;
      _state.isMarketer = false;
    }
  },

  get email() {
    return _state.username;
  },
  get accessToken() {
    return _state.accessToken;
  },
  get accessTokenExpired() {
    return _state.accessTokenExpired;
  },
  get refreshToken() {
    return _state.refreshToken;
  },
  get isAdmin() {
    return _state.isAdmin;
  },
  get isCustomerService() {
    return _state.isCustomerService;
  },
  get isInvestmentSolicitor() {
    return _state.isInvestmentSolicitor;
  },
  get isInvestmentSolicitorAdmin() {
    return true || _state.isInvestmentSolicitorAdmin;
  },
  get isMarketer() {
    return _state.isMarketer;
  },
};

export default tokens;
