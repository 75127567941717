import useAxiosInterceptor from "@/api/hooks/useAxiosInterceptor";
import useAxiosInterceptorCallBacks from "@/api/hooks/useAxiosInterceptorCallBacks";
import React from "react";

const AxiosProvider = ({ children }: { children: React.ReactNode }) => {
  const { onErrorWhileRenewAccessToken, responseOnError } =
    useAxiosInterceptorCallBacks();
  useAxiosInterceptor(onErrorWhileRenewAccessToken, responseOnError);

  return children;
};

export default AxiosProvider;
