import { useAppSelector } from "@/store";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  const { isLoggedIn } = useAppSelector((st) => st.auth.session);
  const location = useLocation();

  console.log("isLoggedIn", isLoggedIn);

  if (!isLoggedIn) {
    // Redirect to login page and save the attempted URL
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
