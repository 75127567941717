import EnvNameWhenNoProd from "@/components/EnvNameWhenNoProd";
import { Typography } from "antd";
import PasswordResetForm from "./PasswordResetForm";
import styled from "@/theme";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const PasswordReset = () => {
  const navigate = useNavigate();

  const onSuccess = () => {
    alert("비밀번호 재설정이 완료되었습니다. 로그인 해주세요.");
    navigate("/login");
  };

  return (
    <Container>
      <EnvNameWhenNoProd />
      <Title level={3}>비밀번호 재설정</Title>

      <PasswordResetForm onSuccess={onSuccess} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export default PasswordReset;
