// src/styles/GlobalStyles.js

import { createGlobalStyle } from "styled-components";

// woff2 폰트 파일 불러오기
import PretendardBlackWoff2 from "@/assets/fonts/woff2/Pretendard-Black.woff2";
import PretendardBoldWoff2 from "@/assets/fonts/woff2/Pretendard-Bold.woff2";
import PretendardExtraBoldWoff2 from "@/assets/fonts/woff2/Pretendard-ExtraBold.woff2";
import PretendardExtraLightWoff2 from "@/assets/fonts/woff2/Pretendard-ExtraLight.woff2";
import PretendardLightWoff2 from "@/assets/fonts/woff2/Pretendard-Light.woff2";
import PretendardMediumWoff2 from "@/assets/fonts/woff2/Pretendard-Medium.woff2";
import PretendardRegularWoff2 from "@/assets/fonts/woff2/Pretendard-Regular.woff2";
import PretendardSemiBoldWoff2 from "@/assets/fonts/woff2/Pretendard-SemiBold.woff2";
import PretendardThinWoff2 from "@/assets/fonts/woff2/Pretendard-Thin.woff2";

// woff 폰트 파일 불러오기
import PretendardBlackWoff from "@/assets/fonts/woff/Pretendard-Black.woff";
import PretendardBoldWoff from "@/assets/fonts/woff/Pretendard-Bold.woff";
import PretendardExtraBoldWoff from "@/assets/fonts/woff/Pretendard-ExtraBold.woff";
import PretendardExtraLightWoff from "@/assets/fonts/woff/Pretendard-ExtraLight.woff";
import PretendardLightWoff from "@/assets/fonts/woff/Pretendard-Light.woff";
import PretendardMediumWoff from "@/assets/fonts/woff/Pretendard-Medium.woff";
import PretendardRegularWoff from "@/assets/fonts/woff/Pretendard-Regular.woff";
import PretendardSemiBoldWoff from "@/assets/fonts/woff/Pretendard-SemiBold.woff";
import PretendardThinWoff from "@/assets/fonts/woff/Pretendard-Thin.woff";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Pretendard-Black';
    src: url(${PretendardBlackWoff2}) format('woff2'),
         url(${PretendardBlackWoff}) format('woff');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard-Bold';
    src: url(${PretendardBoldWoff2}) format('woff2'),
         url(${PretendardBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard-ExtraBold';
    src: url(${PretendardExtraBoldWoff2}) format('woff2'),
         url(${PretendardExtraBoldWoff}) format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard-ExtraLight';
    src: url(${PretendardExtraLightWoff2}) format('woff2'),
         url(${PretendardExtraLightWoff}) format('woff');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard-Light';
    src: url(${PretendardLightWoff2}) format('woff2'),
         url(${PretendardLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard-Medium';
    src: url(${PretendardMediumWoff2}) format('woff2'),
         url(${PretendardMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard-Regular';
    src: url(${PretendardRegularWoff2}) format('woff2'),
         url(${PretendardRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard-SemiBold';
    src: url(${PretendardSemiBoldWoff2}) format('woff2'),
         url(${PretendardSemiBoldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard-Thin';
    src: url(${PretendardThinWoff2}) format('woff2'),
         url(${PretendardThinWoff}) format('woff');
    font-weight: 100;
    font-style: normal;
  }

  body {
    font-family: 'Pretendard-Regular', sans-serif;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
