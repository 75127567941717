import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./authSlice";
import statusReducer from "./statusSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  status: statusReducer
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
