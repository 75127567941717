import { DefaultTheme } from "styled-components";
import colors from "./colors";
import text from "./text";

const base = 16;

const dimens = {
  bigMargin: base * 2,
  margin: base * 1,
  gutter: base * 0.5,
  spacing: base * 0.25,
  thin: base * 0.125,

  buttonNormalHeight: base * 3,
  buttonSmallHeight: base * 2.75,
  buttonDoubleNormalHeight: base * 3,
  buttonDoubleSmallHeight: base * 2.75,
  buttonSubNormalHeight: base * 2.25,
  buttonSubSmallHeight: base * 1.75,

  headerHeight: base * 3,
};

export interface FrismTheme extends DefaultTheme {
  colors: typeof colors;
  dimens: typeof dimens;
  text: typeof text;
}

const normalTheme: FrismTheme = {
  colors,
  dimens,
  text,
};

export default normalTheme;
