/**
 * Do not edit manually.
 * Frism Admin Server
 * OpenAPI spec version: admin
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ApiResponse,
  ConsultantInfoDtoConsultingOptionsDtoApiResponse,
  ConsultingAddressDtoConsultingOptionsDtoApiResponse,
  ConsultingOptionsParams,
  ConsultingTypeCode,
  CrmCustomerInfoDtoApiResponse,
  PostAdminReservationChangedParams,
  PostAdminReservationConfirmedParams,
  PostAdminReservationWaitingParams,
  ReservationAvailableDateListDtoApiResponse,
  ReservationAvailableTimeListDtoApiResponse,
  ReservationChangeParams,
  ReservationChangedResDtoPageDtoApiResponse,
  ReservationConfirmedResDtoPageDtoApiResponse,
  ReservationWaitingResDtoPageDtoApiResponse,
  SearchingParams,
} from "../../model";
import { customAxios } from "../../mutator/customAxios";
import type { ErrorType } from "../../mutator/customAxios";

/**
 * Get 이지만 이름을 body 로 보내기 위해 Post로 사용.
 * @summary 예약 대기 목록 조회
 */
export const postAdminReservationWaiting = (
  searchingParams: SearchingParams,
  params?: PostAdminReservationWaitingParams,
) => {
  return customAxios<ReservationWaitingResDtoPageDtoApiResponse>({
    url: `/admin/reservation/waiting`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchingParams,
    params,
  });
};

export const getPostAdminReservationWaitingMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationWaiting>>,
    TError,
    { data: SearchingParams; params?: PostAdminReservationWaitingParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminReservationWaiting>>,
  TError,
  { data: SearchingParams; params?: PostAdminReservationWaitingParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminReservationWaiting>>,
    { data: SearchingParams; params?: PostAdminReservationWaitingParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return postAdminReservationWaiting(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminReservationWaitingMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminReservationWaiting>>
>;
export type PostAdminReservationWaitingMutationBody = SearchingParams;
export type PostAdminReservationWaitingMutationError = ErrorType<unknown>;

/**
 * @summary 예약 대기 목록 조회
 */
export const usePostAdminReservationWaiting = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationWaiting>>,
    TError,
    { data: SearchingParams; params?: PostAdminReservationWaitingParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminReservationWaiting>>,
  TError,
  { data: SearchingParams; params?: PostAdminReservationWaitingParams },
  TContext
> => {
  const mutationOptions =
    getPostAdminReservationWaitingMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get 이지만 이름을 body 로 보내기 위해 Post로 사용.
 * @summary 예약 확정 목록 조회
 */
export const postAdminReservationConfirmed = (
  searchingParams: SearchingParams,
  params?: PostAdminReservationConfirmedParams,
) => {
  return customAxios<ReservationConfirmedResDtoPageDtoApiResponse>({
    url: `/admin/reservation/confirmed`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchingParams,
    params,
  });
};

export const getPostAdminReservationConfirmedMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationConfirmed>>,
    TError,
    { data: SearchingParams; params?: PostAdminReservationConfirmedParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminReservationConfirmed>>,
  TError,
  { data: SearchingParams; params?: PostAdminReservationConfirmedParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminReservationConfirmed>>,
    { data: SearchingParams; params?: PostAdminReservationConfirmedParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return postAdminReservationConfirmed(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminReservationConfirmedMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminReservationConfirmed>>
>;
export type PostAdminReservationConfirmedMutationBody = SearchingParams;
export type PostAdminReservationConfirmedMutationError = ErrorType<unknown>;

/**
 * @summary 예약 확정 목록 조회
 */
export const usePostAdminReservationConfirmed = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationConfirmed>>,
    TError,
    { data: SearchingParams; params?: PostAdminReservationConfirmedParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminReservationConfirmed>>,
  TError,
  { data: SearchingParams; params?: PostAdminReservationConfirmedParams },
  TContext
> => {
  const mutationOptions =
    getPostAdminReservationConfirmedMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get 이지만 이름을 body 로 보내기 위해 Post로 사용.
 * @summary 예약 변경 및 취소 목록 조회
 */
export const postAdminReservationChanged = (
  searchingParams: SearchingParams,
  params?: PostAdminReservationChangedParams,
) => {
  return customAxios<ReservationChangedResDtoPageDtoApiResponse>({
    url: `/admin/reservation/changed`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchingParams,
    params,
  });
};

export const getPostAdminReservationChangedMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationChanged>>,
    TError,
    { data: SearchingParams; params?: PostAdminReservationChangedParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminReservationChanged>>,
  TError,
  { data: SearchingParams; params?: PostAdminReservationChangedParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminReservationChanged>>,
    { data: SearchingParams; params?: PostAdminReservationChangedParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return postAdminReservationChanged(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminReservationChangedMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminReservationChanged>>
>;
export type PostAdminReservationChangedMutationBody = SearchingParams;
export type PostAdminReservationChangedMutationError = ErrorType<unknown>;

/**
 * @summary 예약 변경 및 취소 목록 조회
 */
export const usePostAdminReservationChanged = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationChanged>>,
    TError,
    { data: SearchingParams; params?: PostAdminReservationChangedParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminReservationChanged>>,
  TError,
  { data: SearchingParams; params?: PostAdminReservationChangedParams },
  TContext
> => {
  const mutationOptions =
    getPostAdminReservationChangedMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 고객 정보 조회
 */
export const getAdminReservationConsultingReservationIdInfo = (
  consultingReservationId: number,
  signal?: AbortSignal,
) => {
  return customAxios<CrmCustomerInfoDtoApiResponse>({
    url: `/admin/reservation/${consultingReservationId}/info`,
    method: "GET",
    signal,
  });
};

export const getGetAdminReservationConsultingReservationIdInfoQueryKey = (
  consultingReservationId: number,
) => {
  return [`/admin/reservation/${consultingReservationId}/info`] as const;
};

export const getGetAdminReservationConsultingReservationIdInfoQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getAdminReservationConsultingReservationIdInfo>
  >,
  TError = ErrorType<unknown>,
>(
  consultingReservationId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getAdminReservationConsultingReservationIdInfo>
        >,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAdminReservationConsultingReservationIdInfoQueryKey(
      consultingReservationId,
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminReservationConsultingReservationIdInfo>>
  > = ({ signal }) =>
    getAdminReservationConsultingReservationIdInfo(
      consultingReservationId,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!consultingReservationId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminReservationConsultingReservationIdInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminReservationConsultingReservationIdInfoQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getAdminReservationConsultingReservationIdInfo>>
  >;
export type GetAdminReservationConsultingReservationIdInfoQueryError =
  ErrorType<unknown>;

/**
 * @summary 고객 정보 조회
 */
export const useGetAdminReservationConsultingReservationIdInfo = <
  TData = Awaited<
    ReturnType<typeof getAdminReservationConsultingReservationIdInfo>
  >,
  TError = ErrorType<unknown>,
>(
  consultingReservationId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getAdminReservationConsultingReservationIdInfo>
        >,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAdminReservationConsultingReservationIdInfoQueryOptions(
      consultingReservationId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 상담사 선택지
 */
export const getAdminReservationConsultantInfo = (signal?: AbortSignal) => {
  return customAxios<ConsultantInfoDtoConsultingOptionsDtoApiResponse>({
    url: `/admin/reservation/consultant-info`,
    method: "GET",
    signal,
  });
};

export const getGetAdminReservationConsultantInfoQueryKey = () => {
  return [`/admin/reservation/consultant-info`] as const;
};

export const getGetAdminReservationConsultantInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAdminReservationConsultantInfoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>
  > = ({ signal }) => getAdminReservationConsultantInfo(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminReservationConsultantInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>
>;
export type GetAdminReservationConsultantInfoQueryError = ErrorType<unknown>;

/**
 * @summary 상담사 선택지
 */
export const useGetAdminReservationConsultantInfo = <
  TData = Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAdminReservationConsultantInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 유선 상담일 경우 해당 api 호출 안함
 * @summary 상담 장소 선택지
 */
export const getAdminReservationConsultingAddress = (signal?: AbortSignal) => {
  return customAxios<ConsultingAddressDtoConsultingOptionsDtoApiResponse>({
    url: `/admin/reservation/consulting-address`,
    method: "GET",
    signal,
  });
};

export const getGetAdminReservationConsultingAddressQueryKey = () => {
  return [`/admin/reservation/consulting-address`] as const;
};

export const getGetAdminReservationConsultingAddressQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAdminReservationConsultingAddressQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>
  > = ({ signal }) => getAdminReservationConsultingAddress(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminReservationConsultingAddressQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>
>;
export type GetAdminReservationConsultingAddressQueryError = ErrorType<unknown>;

/**
 * @summary 상담 장소 선택지
 */
export const useGetAdminReservationConsultingAddress = <
  TData = Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAdminReservationConsultingAddressQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 상담 승인
 */
export const patchAdminReservationConsultingReservationIdAccept = (
  consultingReservationId: number,
  consultingOptionsParams: ConsultingOptionsParams,
) => {
  return customAxios<ApiResponse>({
    url: `/admin/reservation/${consultingReservationId}/accept`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: consultingOptionsParams,
  });
};

export const getPatchAdminReservationConsultingReservationIdAcceptMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
      >,
      TError,
      { consultingReservationId: number; data: ConsultingOptionsParams },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
    >,
    TError,
    { consultingReservationId: number; data: ConsultingOptionsParams },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
      >,
      { consultingReservationId: number; data: ConsultingOptionsParams }
    > = (props) => {
      const { consultingReservationId, data } = props ?? {};

      return patchAdminReservationConsultingReservationIdAccept(
        consultingReservationId,
        data,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchAdminReservationConsultingReservationIdAcceptMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
    >
  >;
export type PatchAdminReservationConsultingReservationIdAcceptMutationBody =
  ConsultingOptionsParams;
export type PatchAdminReservationConsultingReservationIdAcceptMutationError =
  ErrorType<unknown>;

/**
 * @summary 상담 승인
 */
export const usePatchAdminReservationConsultingReservationIdAccept = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
    >,
    TError,
    { consultingReservationId: number; data: ConsultingOptionsParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
  >,
  TError,
  { consultingReservationId: number; data: ConsultingOptionsParams },
  TContext
> => {
  const mutationOptions =
    getPatchAdminReservationConsultingReservationIdAcceptMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
/**
 * @summary 예약 변경
 */
export const postAdminReservationConsultingReservationIdChange = (
  consultingReservationId: number,
  reservationChangeParams: ReservationChangeParams,
) => {
  return customAxios<ApiResponse>({
    url: `/admin/reservation/${consultingReservationId}/change`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: reservationChangeParams,
  });
};

export const getPostAdminReservationConsultingReservationIdChangeMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postAdminReservationConsultingReservationIdChange>
      >,
      TError,
      { consultingReservationId: number; data: ReservationChangeParams },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postAdminReservationConsultingReservationIdChange>
    >,
    TError,
    { consultingReservationId: number; data: ReservationChangeParams },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postAdminReservationConsultingReservationIdChange>
      >,
      { consultingReservationId: number; data: ReservationChangeParams }
    > = (props) => {
      const { consultingReservationId, data } = props ?? {};

      return postAdminReservationConsultingReservationIdChange(
        consultingReservationId,
        data,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostAdminReservationConsultingReservationIdChangeMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postAdminReservationConsultingReservationIdChange>
    >
  >;
export type PostAdminReservationConsultingReservationIdChangeMutationBody =
  ReservationChangeParams;
export type PostAdminReservationConsultingReservationIdChangeMutationError =
  ErrorType<unknown>;

/**
 * @summary 예약 변경
 */
export const usePostAdminReservationConsultingReservationIdChange = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postAdminReservationConsultingReservationIdChange>
    >,
    TError,
    { consultingReservationId: number; data: ReservationChangeParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminReservationConsultingReservationIdChange>>,
  TError,
  { consultingReservationId: number; data: ReservationChangeParams },
  TContext
> => {
  const mutationOptions =
    getPostAdminReservationConsultingReservationIdChangeMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
/**
 * @summary 예약 취소
 */
export const deleteConsultingReservationIdCancel = (
  consultingReservationId: number,
) => {
  return customAxios<ApiResponse>({
    url: `/${consultingReservationId}/cancel`,
    method: "DELETE",
  });
};

export const getDeleteConsultingReservationIdCancelMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteConsultingReservationIdCancel>>,
    TError,
    { consultingReservationId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteConsultingReservationIdCancel>>,
  TError,
  { consultingReservationId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteConsultingReservationIdCancel>>,
    { consultingReservationId: number }
  > = (props) => {
    const { consultingReservationId } = props ?? {};

    return deleteConsultingReservationIdCancel(consultingReservationId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteConsultingReservationIdCancelMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteConsultingReservationIdCancel>>
>;

export type DeleteConsultingReservationIdCancelMutationError =
  ErrorType<unknown>;

/**
 * @summary 예약 취소
 */
export const useDeleteConsultingReservationIdCancel = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteConsultingReservationIdCancel>>,
    TError,
    { consultingReservationId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteConsultingReservationIdCancel>>,
  TError,
  { consultingReservationId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteConsultingReservationIdCancelMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 상담 예약 가능 날짜
 */
export const getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDate =
  (
    consultingTypeCode: ConsultingTypeCode,
    consultantId: string,
    targetDate: string,
    signal?: AbortSignal,
  ) => {
    return customAxios<ReservationAvailableDateListDtoApiResponse>({
      url: `/admin/reservation/${consultingTypeCode}/${consultantId}/${targetDate}/available-date`,
      method: "GET",
      signal,
    });
  };

export const getGetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDateQueryKey =
  (
    consultingTypeCode: ConsultingTypeCode,
    consultantId: string,
    targetDate: string,
  ) => {
    return [
      `/admin/reservation/${consultingTypeCode}/${consultantId}/${targetDate}/available-date`,
    ] as const;
  };

export const getGetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDateQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDate
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingTypeCode: ConsultingTypeCode,
    consultantId: string,
    targetDate: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDate
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDateQueryKey(
        consultingTypeCode,
        consultantId,
        targetDate,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDate
        >
      >
    > = ({ signal }) =>
      getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDate(
        consultingTypeCode,
        consultantId,
        targetDate,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(consultingTypeCode && consultantId && targetDate),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDate
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDateQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDate
      >
    >
  >;
export type GetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDateQueryError =
  ErrorType<unknown>;

/**
 * @summary 상담 예약 가능 날짜
 */
export const useGetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDate =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDate
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingTypeCode: ConsultingTypeCode,
    consultantId: string,
    targetDate: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDate
            >
          >,
          TError,
          TData
        >
      >;
    },
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableDateQueryOptions(
        consultingTypeCode,
        consultantId,
        targetDate,
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary 상담 예약 가능 시간
 */
export const getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTime =
  (
    consultingTypeCode: ConsultingTypeCode,
    consultantId: string,
    targetDate: string,
    signal?: AbortSignal,
  ) => {
    return customAxios<ReservationAvailableTimeListDtoApiResponse>({
      url: `/admin/reservation/${consultingTypeCode}/${consultantId}/${targetDate}/available-time`,
      method: "GET",
      signal,
    });
  };

export const getGetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTimeQueryKey =
  (
    consultingTypeCode: ConsultingTypeCode,
    consultantId: string,
    targetDate: string,
  ) => {
    return [
      `/admin/reservation/${consultingTypeCode}/${consultantId}/${targetDate}/available-time`,
    ] as const;
  };

export const getGetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTimeQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTime
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingTypeCode: ConsultingTypeCode,
    consultantId: string,
    targetDate: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTime
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTimeQueryKey(
        consultingTypeCode,
        consultantId,
        targetDate,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTime
        >
      >
    > = ({ signal }) =>
      getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTime(
        consultingTypeCode,
        consultantId,
        targetDate,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(consultingTypeCode && consultantId && targetDate),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTime
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTimeQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTime
      >
    >
  >;
export type GetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTimeQueryError =
  ErrorType<unknown>;

/**
 * @summary 상담 예약 가능 시간
 */
export const useGetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTime =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTime
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingTypeCode: ConsultingTypeCode,
    consultantId: string,
    targetDate: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTime
            >
          >,
          TError,
          TData
        >
      >;
    },
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetAdminReservationConsultingTypeCodeConsultantIdTargetDateAvailableTimeQueryOptions(
        consultingTypeCode,
        consultantId,
        targetDate,
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };
