import {
  gray,
  negative,
  positive,
  primary,
  shades,
  sub01,
  sub02,
  sub03,
  sub04,
  sub05,
} from './baseColors';

// NOTE: kangseongofdk
// 오버 엔지니어링 의심 중
const buttonColors = {
  buttonPrimarySurface: primary.primary400,
  buttonPrimaryPressed: primary.primary500,
  buttonPrimaryText: shades.white,
  buttonLightPrimarySurface: primary.primary100,
  buttonLightPrimaryPressed: primary.primary200,
  buttonLightPrimaryText: primary.primary400,
  buttonSub01Surface: sub01.sub01_400,
  buttonSub01Pressed: sub01.sub01_500,
  buttonSub01Text: shades.white,
  buttonSub02Surface: sub02.sub02_400,
  buttonSub02Pressed: sub02.sub02_500,
  buttonSub02Text: shades.white,
  buttonSub03Surface: sub03.sub03_400,
  buttonSub03Pressed: sub03.sub03_500,
  buttonSub03Text: shades.white,
  buttonSub04Surface: sub04.sub04_400,
  buttonSub04Pressed: sub04.sub04_500,
  buttonSub04Text: shades.white,
  buttonSub05Surface: sub05.sub05_400,
  buttonSub05Pressed: sub05.sub05_500,
  buttonSub05Text: shades.white,
  buttonNegativeSurface: negative.negative400,
  buttonNegativePressed: negative.negative500,
  buttonNegativeText: shades.white,
  buttonNeutralSurface: gray.gray100,
  buttonNeutralPressed: gray.gray200,
  buttonNeutralText: gray.gray600,

  buttonSecondaryPrimarySurface: primary.primary100,
  buttonSecondaryPrimaryPressed: primary.primary200,
  buttonSecondaryPrimaryText: primary.primary400,
  buttonSecondarySub01Surface: sub01.sub01_100,
  buttonSecondarySub01Pressed: sub01.sub01_200,
  buttonSecondarySub01Text: sub01.sub01_400,
  buttonSecondarySub02Surface: sub02.sub02_100,
  buttonSecondarySub02Pressed: sub02.sub02_200,
  buttonSecondarySub02Text: sub02.sub02_400,
  buttonSecondarySub03Surface: sub03.sub03_100,
  buttonSecondarySub03Pressed: sub03.sub03_200,
  buttonSecondarySub03Text: sub03.sub03_400,
  buttonSecondarySub04Surface: sub04.sub04_100,
  buttonSecondarySub04Pressed: sub04.sub04_200,
  buttonSecondarySub04Text: sub04.sub04_400,
  buttonSecondarySub05Surface: sub05.sub05_100,
  buttonSecondarySub05Pressed: sub05.sub05_200,
  buttonSecondarySub05Text: sub05.sub05_400,
  buttonSecondaryNegativeSurface: negative.negative100,
  buttonSecondaryNegativePressed: negative.negative200,
  buttonSecondaryNegativeText: negative.negative400,
  buttonSecondaryNeutralSurface: gray.gray100,
  buttonSecondaryNeutralPressed: gray.gray200,
  buttonSecondaryNeutralText: gray.gray600,

  buttonSubPrimarySurface: primary.primary400,
  buttonSubPrimaryPressed: primary.primary500,
  buttonSubPrimaryText: shades.white,
  buttonSubLightPrimarySurface: primary.primary100,
  buttonSubLightPrimaryPressed: primary.primary200,
  buttonSubLightPrimaryText: primary.primary400,
  buttonSubSub01Surface: sub01.sub01_200,
  buttonSubSub01Pressed: sub01.sub01_300,
  buttonSubSub01Text: sub01.sub01_400,
  buttonSubSub01_400Surface: sub01.sub01_400,
  buttonSubSub01_400Pressed: sub01.sub01_500,
  buttonSubSub01_400Text: shades.white,
  buttonSubSub02Surface: sub02.sub02_200,
  buttonSubSub02Pressed: sub02.sub02_300,
  buttonSubSub02Text: sub02.sub02_400,
  buttonSubSub03Surface: sub03.sub03_200,
  buttonSubSub03Pressed: sub03.sub03_300,
  buttonSubSub03Text: sub03.sub03_400,
  buttonSubSub04Surface: sub04.sub04_200,
  buttonSubSub04Pressed: sub04.sub04_300,
  buttonSubSub04Text: sub04.sub04_400,
  buttonSubSub05Surface: sub05.sub05_200,
  buttonSubSub05Pressed: sub05.sub05_300,
  buttonSubSub05Text: sub05.sub05_400,
  buttonSubNegativeSurface: negative.negative400,
  buttonSubNegativePressed: negative.negative500,
  buttonSubNegativeText: shades.white,
  buttonSubNegativeSubSurface: negative.negative100,
  buttonSubNegativeSubPressed: negative.negative200,
  buttonSubNegativeSubText: negative.negative400,
  buttonSubNeutralSurface: gray.gray100,
  buttonSubNeutralPressed: gray.gray200,
  buttonSubNeutralText: gray.gray600,

  buttonPositiveSurface: positive.positive400,
  buttonPositivePressed: positive.positive500,
  buttonPositiveText: shades.white,

  buttonDisabledSurface: gray.gray200,
  buttonDisabledText: shades.white,
};

export default buttonColors;
